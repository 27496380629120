import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useAuth0 } from "@auth0/auth0-react"
import { makeStyles, createStyles } from "@mui/styles"
import List from "@mui/material/List"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import IconExpandLess from "@mui/icons-material/ExpandLess"
import IconExpandMore from "@mui/icons-material/ExpandMore"
import AppMenuItemComponent from "./AppMenuItemComponent"

const sessionStorageKey = "affadmin-navs-expanded"

const getNavsExpanded = () =>
  sessionStorage.getItem(sessionStorageKey)
    ? JSON.parse(sessionStorage.getItem(sessionStorageKey))
    : []

const AppMenuItem = (props) => {
  const { name, link, Icon, fields, items = [], logoutButton } = props
  const classes = useStyles()
  const isExpandable = items && items.length > 0
  const [open, setOpen] = useState(false)

  const { isAuthenticated, isLoading, logout } = useAuth0()

  useEffect(() => {
    const navsExpanded = getNavsExpanded()
    if (navsExpanded && navsExpanded.indexOf(props.name) !== -1) {
      setOpen(true)
    }
  }, [props.name])

  function handleClick() {
    const navsExpanded = getNavsExpanded()
    if (!open) {
      navsExpanded.push(props.name)
    } else if (navsExpanded.indexOf(props.name) !== -1) {
      navsExpanded.splice(navsExpanded.indexOf(props.name), 1)
    }
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(navsExpanded))

    setOpen(!open)
  }

  function handleLogoutClicked() {
    if (!isLoading && isAuthenticated) {
      logout({ returnTo: window.location.origin })
    }
  }

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      link={link}
      fields={fields}
      onClick={logoutButton ? handleLogoutClicked : handleClick}
    >
      <>
        {!!Icon && (
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={name}
          inset={!Icon}
          className={classes.menuItemText}
        />
        {/* Display the expand menu if the item has children */}
        {isExpandable && !open && <IconExpandMore />}
        {isExpandable && open && <IconExpandLess />}
      </>
    </AppMenuItemComponent>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse
      className={classes.collapseArea}
      in={open}
      timeout="auto"
      unmountOnExit
    >
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      "&.active": {
        background: theme.colors.drawer.activeBackground,
        borderRadius: theme.colors.drawer.activeBorderRadius,
        "& .MuiListItemIcon-root": {
          color: theme.colors.drawer.activeForeground,
        },
        "& $menuItemText span": {
          color: theme.colors.drawer.activeForeground,
        },
      },
      "&:not(.active)": {
        "&:hover": {
          "& $menuItemText span": {
            color: theme.colors.drawer.hoverText,
          },
        },
      },
      paddingTop: 6,
      paddingBottom: 6,
    },
    menuItemIcon: {
      color: theme.colors.drawer.icon,
      minWidth: 28,
      "& svg": {
        fontSize: "1.2rem",
      },
    },
    menuItemText: {
      "& span": {
        fontSize: 14,
        fontWeight: 500,
        color: theme.colors.drawer.text,
      },
    },
    collapseArea: {
      backgroundColor: theme.colors.drawer.collapseFirstLevel,
      "& $collapseArea": {
        backgroundColor: theme.colors.drawer.collapseSecondLevel,
        "& $menuItem": {
          paddingLeft: 32,
        },
        "& $menuItemText span": {
          color: "#cecece",
        },
      },
    },
  })
)

AppMenuItem.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  Icon: PropTypes.elementType,
  fields: PropTypes.array,
  items: PropTypes.array,
  logoutButton: PropTypes.bool,
}

export default AppMenuItem
