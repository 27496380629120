import React, { useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { makeStyles } from "@mui/styles"
import { deepOrange } from "@mui/material/colors"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

const useStyles = makeStyles((theme) => ({
  root: { display: "inline-flex", "& svg": { color: "#fff" } },
  avatarButton: {
    "&:hover": {
      opacity: 0.9,
    },
  },
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    cursor: "pointer",
  },
}))

const UserComponent = (props) => {
  const classes = useStyles()

  const { user, isAuthenticated, isLoading, logout } = useAuth0()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      {!isLoading && isAuthenticated && (
        <div>
          <Button className={classes.avatarButton} onClick={handleClick}>
            <Avatar className={classes.avatar} alt={user.email}>
              {user && user.name ? user.name.substring(0, 1).toUpperCase() : ""}
            </Avatar>
            <ArrowDropDownIcon />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            // getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  )
}

export default UserComponent
