import React from "react"
import { makeStyles } from "@mui/styles"
import { Pie } from "@visx/shape"
import { Group } from "@visx/group"
import { useTooltip, useTooltipInPortal } from "@visx/tooltip"
import { localPoint } from "@visx/event"
import PropTypes from "prop-types"

const frequency = (d) => d.frequency

const margin = { top: 20, right: 20, bottom: 20, left: 20 }

const useStyles = makeStyles((theme) => ({
  graphic: {
    "&:hover": { opacity: 0.89 },
  },
}))

export default function PieChartComponent(props) {
  const { width, height, data } = props

  const classes = useStyles()

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: false,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  })

  const handleMouseOver = (event) => {
    const coords = localPoint(event.target.ownerSVGElement, event)
    const tooltipTitle = event.target.attributes["tooltiptitle"]
      ? event.target.attributes["tooltiptitle"].value
      : ""
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: tooltipTitle,
    })
  }

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const top = centerY + margin.top
  const left = centerX + margin.left
  const pieSortValues = (a, b) => b - a

  return (
    <>
      <svg ref={containerRef} width={width} height={height}>
        <Group top={top} left={left}>
          <Pie
            data={data}
            pieValue={frequency}
            pieSortValues={pieSortValues}
            outerRadius={radius}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { name, tooltipTitle, color } = arc.data
                const [centroidX, centroidY] = pie.path.centroid(arc)
                const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1
                const arcPath = pie.path(arc)
                const arcFill = color
                return (
                  <g
                    key={`arc-${name}-${index}`}
                    className={classes.graphic}
                    onMouseOver={handleMouseOver}
                    onMouseOut={hideTooltip}
                  >
                    {/*<path d={arcPath} fill={arcFill} tooltiptitle={tooltipTitle} />*/}
                    <path d={arcPath} fill={arcFill} />
                    {hasSpaceForLabel && (
                      <text
                        x={centroidX}
                        y={centroidY}
                        dy=".33em"
                        fill="#ffffff"
                        fontSize={12}
                        textAnchor="middle"
                        pointerEvents="none"
                      >
                        {arc.data.name}
                      </text>
                    )}
                  </g>
                )
              })
            }}
          </Pie>
        </Group>
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <strong>{tooltipData}</strong>
        </TooltipInPortal>
      )}
    </>
  )
}

PieChartComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
}
