/* eslint-disable no-use-before-define */
import React from "react"
import PropTypes from "prop-types"
import TextField from "@mui/material/TextField"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { Checkbox } from "@mui/material"

const AutocompleteWithSelectAll = ({
  items,
  selectedValues,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onToggleOption,
  onClearOptions,
  onSelectAll,
  getOptionLabel,
}) => {
  const allSelected =
    items && selectedValues && items.length === selectedValues.length
  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected)
  }

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option.name === "select-all")) {
        if (window.confirm("Do you wish to select/unselect all options?")) {
          handleToggleSelectAll()
        }
      } else {
        onToggleOption && onToggleOption(selectedOptions)
      }
    } else if (reason === "clear") {
      onClearOptions && onClearOptions()
    }
  }
  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.name === "select-all" // To control the state of 'select-all' checkbox
        ? { checked: allSelected }
        : {}
    return option.name === "select-all" ? (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.title}
      </li>
    ) : (
      <li {...props}>{option.title}</li>
    )
  }
  const inputRenderer = (params) => (
    <TextField
      {...params}
      variant="standard"
      label={label}
      placeholder={placeholder}
      inputProps={{
        ...params.inputProps,
        autoComplete: "new-password",
      }}
    />
  )
  const isOptionEqualToValue = (option, anotherOption) => {
    return option.name === anotherOption.name
  }
  const filter = createFilterOptions()
  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={limitTags}
      options={items}
      value={selectedValues}
      disableCloseOnSelect
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        return [{ title: selectAllLabel, name: "select-all" }, ...filtered]
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  )
}

AutocompleteWithSelectAll.defaultProps = {
  limitTags: 12,
  items: [],
  selectedValues: [],
  getOptionLabel: (value) => value,
}

AutocompleteWithSelectAll.propTypes = {
  items: PropTypes.array,
  selectedValues: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  selectAllLabel: PropTypes.string,
  noOptionsText: PropTypes.string,
  limitTags: PropTypes.number,
  onToggleOption: PropTypes.func,
  onClearOptions: PropTypes.func,
  onSelectAll: PropTypes.func,
  getOptionLabel: PropTypes.func,
}

export default AutocompleteWithSelectAll
