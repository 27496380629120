import React, { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { makeStyles } from "@mui/styles"
import { getItems } from "../../helpers/ItemHelper"
import { Constants } from "../../config"
import LinkStatuses from "./LinkStatuses"
import CasinosPerMarket from "./CasinosPerMarket"
import CasinoStatuses from "./CasinoStatuses"
import FieldOverview from "./FieldOverview"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import RecalculateScores from "./RecalculateScores"
const paperBackgroundImage = require("../../images/photo-mountains.jpeg")

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 0,
    position: "relative",
  },
  paperWithBackgroundImage: {
    padding: theme.spacing(4),
    backgroundImage: "url(" + paperBackgroundImage + ")",
    backgroundSize: "100%",
    backgroundPosition: "18% 45%",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  },
  dayTitle: {
    position: "absolute",
    right: 20,
    top: 10,
    fontSize: "1rem",
    color: "#f1f1f1",
  },
}))

const dayTitle = () => {
  const dateObj = new Date()
  const weekDay = dateObj.toLocaleString("default", { weekday: "long" })
  return (
    weekDay.charAt(0).toUpperCase() +
    weekDay.slice(1) +
    " " +
    dateObj.getDate() +
    "/" +
    (parseInt(dateObj.getMonth()) + 1)
  )
}

const apiRoutes = Constants.homeScreen.apiRoutes

const loadItems = (token, apiRoutes, index, itemsArray, callback) => {
  const apiRoute = apiRoutes[index]
  getItems(token, apiRoute.url, (results) => {
    if (results && Array.isArray(results)) {
      itemsArray.push({
        shortName: apiRoute.shortName,
        name: apiRoute.name,
        results,
      })
      index++
      if (index < apiRoutes.length) {
        loadItems(token, apiRoutes, index, itemsArray, callback)
      } else {
        callback(itemsArray)
      }
    } else {
      callback(false)
    }
  })
}

const HomeScreen = (props) => {
  const classes = useStyles()

  const { getAccessTokenSilently } = useAuth0()

  const [itemsArray, setItemsArray] = useState([]),
    [errorDetails, setErrorDetails] = useState(undefined),
    [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      loadItems(token, apiRoutes, 0, [], (itemsArray) => {
        setIsLoading(false)
        if (itemsArray) {
          setItemsArray(itemsArray)
        } else {
          setErrorDetails("Could not load diagram")
        }
      })
    })
  }, [getAccessTokenSilently])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={`${classes.paper} ${classes.paperWithBackgroundImage}`}>
            <Typography variant="overline" className={classes.dayTitle}>
              {dayTitle()}
            </Typography>

            <h2>Welcome to stage</h2>
            <Typography paragraph>To Affiliate Admin</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <RecalculateScores
              markets={itemsArray}
              isLoading={isLoading}
              errorDetails={errorDetails}
            />
          </Paper>
        </Grid>
        {Constants.homeScreen.fieldOverviewSettings && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FieldOverview
                markets={itemsArray}
                isLoading={isLoading}
                errorDetails={errorDetails}
              />
            </Paper>
          </Grid>
        )}
        {Constants.homeScreen.enableLinkStatuses && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <LinkStatuses />
            </Paper>
          </Grid>
        )}
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <CasinoStatuses
              markets={itemsArray}
              isLoading={isLoading}
              errorDetails={errorDetails}
            />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <CasinosPerMarket
              markets={itemsArray}
              isLoading={isLoading}
              errorDetails={errorDetails}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default HomeScreen
