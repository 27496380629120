import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { getItems } from "../../helpers/ItemHelper"
import { deepDiffMapper, countryToFlag } from "../../helpers/GeneralHelper"
import { Constants } from "../../config"
import { useAuth0 } from "@auth0/auth0-react"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Chip from "@mui/material/Chip"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Avatar from "@mui/material/Avatar"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"

const useStyles = makeStyles((theme) => ({
  spinner: {
    margin: "0 auto",
    display: "block",
    height: 60,
  },
  dialogContainer: {
    width: 860,
  },
  dialogTitle: {
    "& h2": {
      fontWeight: 400,
    },
  },
  dialogMarketSubtitle: {
    fontSize: 16,
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: ".7rem",
  },
  jsonChanges: {
    marginLeft: theme.spacing(2),
  },
  revision: {
    marginBottom: theme.spacing(4),
    display: "flex",
  },
  revisionIconContainer: {
    margin: "0 10px 0 0",
    paddingTop: 1,
  },
  revisionIcon: {
    fontSize: "1rem",
  },
  revisionAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  revisionDate: {
    fontWeight: 400,
    fontSize: 10,
  },
  revisionHeading: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
  },
  changesContainer: { fontSize: 12, marginLeft: theme.spacing(1) },
  changeRow: {
    "& span": {
      fontWeight: 400,
    },
    fontWeight: 500,
    margin: theme.spacing(0.5, 0),
  },
  scopePicker: {
    marginBottom: 30,
  },
}))

const Changelog = (props) => {
  const classes = useStyles()

  const { getAccessTokenSilently } = useAuth0()

  const {
    dismissChangelog,
    item,
    params,
    apiUrl,
    initialFields,
    changelogFetchFailed,
  } = props

  const [revisions, setRevisions] = useState([]),
    [fields, setFields] = useState(initialFields),
    [isLoading, setIsLoading] = useState(true),
    [historyApiUrl, setHistoryApiUrl] = useState(),
    [currentMarket, setCurrentMarket] = useState(undefined)

  const getHistoryUrl = useCallback(
    (url) => {
      return url.replace(/\/(?=[^/]*$)/, "_history/" + item.internal_name)
    },
    [item.internal_name]
  )

  const isolateChanges = (data) => {
    const tempArray = []
    data.push({})
    for (let i = 0; i < data.length - 1; i++) {
      const itemDiff = deepDiffMapper.map(
        data[i],
        data[i + 1],
        Constants.history && Constants.history.metaFields
          ? Constants.history.metaFields
          : []
      )

      for (let j = 0; j < Object.keys(itemDiff).length; j++) {
        const diffFieldName = Object.keys(itemDiff)[j]
        if (Constants.history.metaFields.indexOf(diffFieldName) < 0) {
          itemDiff[diffFieldName].prev = data[i + 1][diffFieldName]
        }
      }

      tempArray.push(itemDiff)
    }

    return tempArray
  }

  const formatFields = (value, fieldDef) => {
    if (fieldDef.type === "date" && value) {
      value = new Date(value).toLocaleString()
    }

    return value
  }

  const scopeSelected = (url, market, scopeFields) => {
    if (scopeFields !== fields) {
      setFields(scopeFields)
    }
    setHistoryApiUrl(url + item.internal_name)
    setCurrentMarket(market)
  }

  const getJsonChanges = (prev, value) => {
    const prevArray = prev ? JSON.parse(prev) : []
    const valueArray = value ? JSON.parse(value) : []

    const added = prevArray
      ? valueArray.filter(
          (v) => prevArray.filter((p) => p.name === v.name).length < 1
        )
      : valueArray

    const addedChips =
      added &&
      added.map((a) => (
        <Chip className={classes.chip} key={a.name} size="small" label={a.title} />
      ))

    const removed = prevArray
      ? prevArray.filter(
          (p) => valueArray.filter((v) => v.name === p.name).length < 1
        )
      : false

    const removedChips =
      removed &&
      removed.map((a) => (
        <Chip className={classes.chip} key={a.name} size="small" label={a.title} />
      ))

    return (
      <div className={classes.jsonChanges}>
        {addedChips && addedChips.length > 0 && (
          <div>
            <span>Added</span> {addedChips}
          </div>
        )}{" "}
        {removedChips && removedChips.length > 0 && (
          <div>
            <span>Removed</span> {removedChips}
          </div>
        )}
      </div>
    )
  }

  const renderChanges = (revision) => {
    const returnHtml = []

    for (let i = 0; i < Object.keys(revision).length; i++) {
      const fieldName = Object.keys(revision)[i]
      let value = revision[fieldName].data
      let prev = revision[fieldName].prev

      const fieldDef =
        fields.filter((fd) => fd.name === fieldName).length > 0
          ? fields.filter((fd) => fd.name === fieldName)[0]
          : false

      if (!fieldDef || Constants.history.metaFields.indexOf(fieldName) > -1) {
        continue
      }

      const jsonChanges = fieldDef.json ? getJsonChanges(prev, value) : false
      if (!jsonChanges) {
        value = formatFields(value, fieldDef)
        prev = formatFields(prev, fieldDef)
      }

      returnHtml.push(
        <div className={classes.changeRow} key={fieldName}>
          {fieldDef.title}{" "}
          {jsonChanges || (
            <>
              {prev && (
                <>
                  <span>from</span> {prev}
                </>
              )}{" "}
              <span>to</span> {value || <i> empty</i>}
            </>
          )}
        </div>
      )
    }
    return returnHtml
  }

  const renderRevision = (revision) => {
    const date = new Date(revision.dt_datetime.data)
    const insertion =
      revision[Constants.history.actionField].data === Constants.history.insertValue
    const deletion =
      revision[Constants.history.actionField].data === Constants.history.deleteValue
    const revisionTitle = insertion ? (
      <>
        {revision.updated_by.data || "Someone"} <span>added</span> {item.name}{" "}
        <span>to</span> {params.market}
      </>
    ) : deletion ? (
      <>
        {revision.updated_by.data || "Someone"} <span>removed</span> {item.name}{" "}
        <span>from</span> {params.market}
      </>
    ) : (
      <>
        {revision.updated_by.data || "Someone"} <span>changed</span>
      </>
    )

    return (
      <>
        <div className={classes.revisionIconContainer}>
          <Avatar className={classes.revisionAvatar}>
            {insertion ? (
              <AddIcon className={classes.revisionIcon} />
            ) : deletion ? (
              <DeleteIcon className={classes.revisionIcon} />
            ) : (
              <EditIcon className={classes.revisionIcon} />
            )}
          </Avatar>
        </div>
        <div>
          <div className={classes.revisionHeading}>
            <div className={classes.revisionDate}>{date.toLocaleString()}</div>
            {revisionTitle}
          </div>
          {!insertion && (
            <div className={classes.changesContainer}>{renderChanges(revision)}</div>
          )}
        </div>
      </>
    )
  }

  const renderEmptyMessage = () => {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          It&apos;s empty in here
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          This item has not been changed since changelog history was introduced.
          Future changes will appear here.
        </Typography>
      </>
    )
  }

  useEffect(() => {
    setCurrentMarket(params.market)
  }, [params.market])

  useEffect(() => {
    setHistoryApiUrl(getHistoryUrl(apiUrl))
  }, [apiUrl, getHistoryUrl])

  useEffect(() => {
    if (historyApiUrl) {
      getAccessTokenSilently().then((token) => {
        getItems(token, historyApiUrl, (data, error) => {
          setIsLoading(false)
          if (data && !error) {
            data.sort((a, b) => {
              const aDate = new Date(a.dt_datetime)
              const bDate = new Date(b.dt_datetime)
              return bDate - aDate
            })

            const dataChangesOnly = isolateChanges(data)

            setRevisions(dataChangesOnly)
          } else {
            changelogFetchFailed()
          }
        })
      })
    }
  }, [historyApiUrl, changelogFetchFailed, getAccessTokenSilently])

  return (
    <div>
      <Dialog
        open={true}
        maxWidth={false}
        onClose={dismissChangelog}
        aria-labelledby="changelog-dialog-title"
        aria-describedby="changelog-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="changelog-dialog-title">
          Changelog - <strong>{item.name}</strong>{" "}
          {currentMarket === "root" ? (
            <span className={classes.dialogMarketSubtitle}>(Central database)</span>
          ) : currentMarket === "row" ? (
            <span className={classes.dialogMarketSubtitle}>(Rest of the World)</span>
          ) : (
            currentMarket && countryToFlag(currentMarket)
          )}
        </DialogTitle>
        <DialogContent
          className={classes.dialogContainer}
          id="changelog-dialog-description"
        >
          <ButtonGroup
            className={classes.scopePicker}
            aria-label="Scope picker button group"
          >
            {Constants.history.marketConfig.map((b) => (
              <Button
                key={b.market}
                color={
                  historyApiUrl && historyApiUrl.indexOf(b.url) > -1
                    ? "primary"
                    : "inherit"
                }
                onClick={() => scopeSelected(b.url, b.market, b.fields)}
              >
                {b.title}
              </Button>
            ))}
          </ButtonGroup>
          {isLoading ? (
            <CircularProgress className={classes.spinner} color="inherit" />
          ) : revisions && revisions.length > 0 ? (
            revisions.map((r) => {
              return (
                <div
                  className={classes.revision}
                  key={`${r.id.data}-${r.revision.data}`}
                >
                  {renderRevision(r)}
                </div>
              )
            })
          ) : (
            renderEmptyMessage()
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={dismissChangelog} color="primary">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

Changelog.propTypes = {
  item: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  apiUrl: PropTypes.string.isRequired,
  changelogFetchFailed: PropTypes.func.isRequired,
  initialFields: PropTypes.array.isRequired,
  dismissChangelog: PropTypes.func.isRequired,
}

export default Changelog
