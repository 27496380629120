/* eslint-disable react/display-name */
import React, { useState, forwardRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useStateValue } from "../../state"
import { useAuth0 } from "@auth0/auth0-react"
import { putItems } from "../../helpers/ItemHelper"
import AddBox from "@mui/icons-material/AddBox"
import ArrowDownward from "@mui/icons-material/ArrowDownward"
import SnackbarComponent from "../reusable/SnackbarComponent"
import Check from "@mui/icons-material/Check"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import Clear from "@mui/icons-material/Clear"
import DeleteOutline from "@mui/icons-material/DeleteOutline"
import Edit from "@mui/icons-material/Edit"
import FilterList from "@mui/icons-material/FilterList"
import FirstPage from "@mui/icons-material/FirstPage"
import LastPage from "@mui/icons-material/LastPage"
import Remove from "@mui/icons-material/Remove"
import SaveAlt from "@mui/icons-material/SaveAlt"
import Search from "@mui/icons-material/Search"
import ViewColumn from "@mui/icons-material/ViewColumn"
import MaterialTable, { MTableBodyRow, MTableEditRow } from "material-table"
import { makeStyles } from "@mui/styles"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

// Styles to hide edit actions column
const useStyles = makeStyles((theme) => ({
  tableRow: {
    "& td.MuiTableCell-paddingNone": {
      display: "none",
    },
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
}))

function EditableItemTable(props) {
  const { config, rows, apiUrl, reload } = props

  const { title, pageSize, columns } = config
    ? config
    : { title: "", pageSize: 0, columns: [] }

  const classes = useStyles()

  // eslint-disable-next-line no-unused-vars
  const [{ appBarContext }, dispatch] = useStateValue()

  const { getAccessTokenSilently, user } = useAuth0()

  const { name: currentUserName } = user

  const [data, setData] = useState([]),
    [showSnackbar, setShowSnackbar] = useState(false),
    [snackbarProps, setSnackbarProps] = useState({})

  // Handle close snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSnackbar(false)
  }

  // Validation before save
  const validateValue = (value, column) => {
    let returnValue = value

    if (column.type === "numeric") {
      if (isNaN(value)) {
        returnValue = 0
      }
      if (
        (column.max && parseFloat(returnValue) > column.max) ||
        (column.min && parseFloat(returnValue) < column.min)
      ) {
        returnValue = false
      }
    }

    return returnValue
  }

  // On bulk update
  const onBulkUpdate = (changes) =>
    new Promise((resolve, reject) => {
      const updatedItems = []
      Object.keys(changes).forEach((k) => {
        const { oldData, newData } = changes[k]
        const updatedItem = {}
        Object.keys(newData).forEach((key) => {
          const column =
            columns.filter((c) => c.field === key).length > 0
              ? columns.filter((c) => c.field === key)[0]
              : false
          if (newData[key] !== oldData[key] && column) {
            const newValue = validateValue(newData[key], column)
            if (newValue === false) {
              setShowSnackbar(true)
              setSnackbarProps({
                class: classes.errorSnackbar,
                messageClass: classes.snackbarMessage,
                closeEvent: handleCloseSnackbar,
                message: "Sorry, one or more fields have disallowed values",
                icon: <ErrorOutlineIcon />,
                autoHideDuration: 3000,
              })
              reject()
              return
            } else {
              updatedItem[key] = newValue
            }
          }
        })
        if (Object.keys(updatedItem).length > 0 && newData.id) {
          updatedItem["id"] = newData.id
          updatedItems.push(updatedItem)
        }
      })

      if (updatedItems.length > 0) {
        dispatch({
          type: "changeAppBar",
          newAppBar: { isSaving: true },
        })

        getAccessTokenSilently().then((token) => {
          putItems(
            token,
            apiUrl,
            updatedItems,
            currentUserName,
            columns,
            (results) => {
              dispatch({
                type: "changeAppBar",
                newAppBar: { isSaving: false, saveEnabled: false },
              })

              if (results && !results.errno) {
                resolve()
                setTimeout(() => reload(), 100)
              } else {
                console.log("error: ", results)
                reject()
                setShowSnackbar(true)
                setSnackbarProps({
                  class: classes.errorSnackbar,
                  messageClass: classes.snackbarMessage,
                  closeEvent: handleCloseSnackbar,
                  message: "Sorry, an error occured when saving",
                  icon: <ErrorOutlineIcon />,
                })
              }
            }
          )
        })
      } else {
        setShowSnackbar(true)
        setSnackbarProps({
          messageClass: classes.snackbarMessage,
          closeEvent: handleCloseSnackbar,
          message: "No updates submitted",
          icon: <ErrorOutlineIcon />,
          autoHideDuration: 2000,
        })
        resolve()
      }
    })

  // useEffect for rows prop change
  useEffect(() => {
    const newRows = rows
    // Set any null column values to undefined instead
    columns.forEach((c) => {
      const rowsWithNullValue = newRows.filter(
        (r) => !(c.field in r) || r[c.field] === null
      )
      rowsWithNullValue.forEach((r) => (r[c.field] = ""))
    })
    setData(newRows)
  }, [rows, columns])

  return config ? (
    <div>
      {showSnackbar && <SnackbarComponent open={showSnackbar} {...snackbarProps} />}

      <MaterialTable
        icons={tableIcons}
        options={{
          search: false,
          pageSize,
        }}
        title={title}
        columns={columns}
        data={data}
        editable={{
          onBulkUpdate,
        }}
        components={{
          Row: (props) => <MTableBodyRow {...props} className={classes.tableRow} />,
          EditRow: (props) => (
            <MTableEditRow {...props} className={classes.tableRow} />
          ),
        }}
      />
    </div>
  ) : null
}

export default EditableItemTable

EditableItemTable.propTypes = {
  config: PropTypes.object,
  rows: PropTypes.array.isRequired,
  apiUrl: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
}
