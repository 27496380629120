import { Fields } from "../"

export const Constants = {
  internalNameColumn: "internal_name",
  nameColumn: "name",
  statusFieldsType: "casinos",
  statusFields: [
    { name: "status_root", title: "Central database", root: true },
    { name: "status_country", title: "Country database" },
    { name: "status_site", title: "Site database" },
  ],
  activeStatus: "1. Active",
  emptyFieldsWarnings: [
    {
      itemType: "casinos",
      disableInMarkets: ["row", "root"],
      fields: ["tracking_url", "tracking_url_country", "tracking_url_site"],
      requireAll: false,
      message: "Important: No tracking url seem to be set for this item",
      label: "Missing tracking url",
    },
    {
      itemType: "casinos",
      onlyInMarkets: ["row"],
      fields: ["tracking_url", "tracking_url_site"],
      requireAll: false,
      message:
        "Important: No tracking url seem to be set for this item. See Central Database level.",
      label: "Missing tracking url",
    },
  ],
  homeScreen: {
    enableLinkStatuses: false,
    fieldOverviewSettings: {
      title: "Casinos with manual positions",
      emptyMessage: "No casinos with manual positions could be found at this moment",
      getKey: (item) =>
        `${item["id"]}-${item["internal_name"]}-${item["position"]}-${item["start_date"]}`,
      jsonTransformFieldName: "manual_positions",
      fieldsToDisplay: [
        {
          name: "name",
          title: "Casino",
          mainField: true,
          prefixFlagField: "_market",
        },
        {
          name: "manual_positions",
          title: "Manual positions",
          required: true,
          jsonArray: true,
        },
        { name: "position", title: "Position" },
        { name: "start_date", title: "Start date", filterStartDate: true },
        { name: "end_date", title: "End date", filterEndDate: true },
      ],
      enableDateFilter: true,
    },
    apiRoutes: [
      {
        shortName: "Central",
        name: "Central database",
        url: "/root/casinos/root",
      },
      {
        shortName: "AU",
        name: "Australia",
        url: "/root/casinos/au",
      },
      { shortName: "CA", name: "Canada", url: "/root/casinos/ca" },
      { shortName: "IN", name: "India", url: "/root/casinos/in" },
      {
        shortName: "NZ",
        name: "New Zeeland",
        url: "/root/casinos/nz",
      },
      {
        shortName: "ZA",
        name: "South Africa",
        url: "/root/casinos/za",
      },
      {
        shortName: "UK",
        name: "United Kingdom",
        url: "/root/casinos/uk",
      },
      {
        shortName: "ROW",
        name: "Rest of the World",
        url: "/root/casinos/row",
      },
    ],
  },
  history: {
    metaFields: [
      "id",
      "revision",
      "updated_by",
      "revision",
      "dt_datetime",
      "action",
    ],
    actionField: "action",
    insertValue: "insert",
    deleteValue: "delete",
    marketConfig: [
      {
        title: "Central",
        market: "root",
        url: "/root/casinos/root_history/",
        fields: Fields.casinosRoot,
      },
      {
        title: "AU",
        market: "au",
        url: "/root/casinos/au_history/",
        fields: Fields.casinosCountry,
      },
      {
        title: "CA",
        market: "ca",
        url: "/root/casinos/ca_history/",
        fields: Fields.casinosCountry,
      },
      {
        title: "IN",
        market: "in",
        url: "/root/casinos/in_history/",
        fields: Fields.casinosCountry,
      },
      {
        title: "NZ",
        market: "nz",
        url: "/root/casinos/nz_history/",
        fields: Fields.casinosCountry,
      },
      {
        title: "ROW",
        market: "row",
        url: "/root/casinos/row_history/",
        fields: Fields.casinosCountry,
      },
      {
        title: "UK",
        market: "uk",
        url: "/root/casinos/uk_history/",
        fields: Fields.casinosCountry,
      },
      {
        title: "ZA",
        market: "za",
        url: "/root/casinos/za_history/",
        fields: Fields.casinosCountry,
      },
    ],
  },
}
