import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import ErrorDisplay from "../reusable/ErrorDisplay"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import { Select, MenuItem, FormControl, Button, InputLabel } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { useAuth0 } from "@auth0/auth0-react"
import { recalculateScore } from "../../helpers/ItemHelper"
import SnackbarComponent from "../reusable/SnackbarComponent"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
  heading: {
    fontSize: 16,
    color: "#575757",
    marginBottom: 20,
  },
  subHeading: {
    fontSize: 12,
    color: "#575757",
  },
  chartContainer: {
    textAlign: "center",
  },
}))

const RecalculateScores = (props) => {
  const { markets, errorDetails, isLoading } = props
  const classes = useStyles()

  const { getAccessTokenSilently, user } = useAuth0()
  const { name: currentUserName } = user

  const [selectedOption, setSelectedOption] = useState("")
  const [availableMarkets, setAvailableMarkets] = useState([])
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarProps, setSnackbarProps] = useState({})

  useEffect(() => {
    if (Array.isArray(markets) && markets.length > 0) {
      const tempAvailableMarkets = ["All Markets"]
      markets.forEach((market) => {
        if (market.shortName !== "Central") {
          tempAvailableMarkets.push(market.shortName)
        }
      })
      setAvailableMarkets(tempAvailableMarkets)
      setSelectedOption(tempAvailableMarkets[0])
    }
  }, [markets])

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSnackbar(false)
  }

  const sendRecalculateRequest = async (market) => {
    getAccessTokenSilently().then((token) => {
      recalculateScore(
        token,
        `/recalculatescore`,
        currentUserName,
        market,
        (results) => {
          if (results && results.status === 200) {
            setShowSnackbar(true)
            setSnackbarProps({
              messageClass: classes.snackbarMessage,
              closeEvent: handleCloseSnackbar,
              message: `Scores for ${market} market recalculated`,
              icon: <CheckIcon />,
              autoHideDuration: 1500,
            })
          } else {
            console.log("error: ", results)
            setShowSnackbar(true)
            setSnackbarProps({
              class: classes.errorSnackbar,
              messageClass: classes.snackbarMessage,
              closeEvent: handleCloseSnackbar,
              message: `Sorry, an error occurred when saving in market ${market}`,
              icon: <ErrorOutlineIcon />,
            })
          }
        }
      )
    })
  }

  const handleButtonClick = async () => {
    if (selectedOption === "All Markets") {
      availableMarkets.forEach((market) => {
        if (market !== "All Markets") {
          sendRecalculateRequest(market)
        }
      })
    } else {
      await sendRecalculateRequest(selectedOption)
    }
  }

  return (
    <div className={classes.root}>
      {showSnackbar && <SnackbarComponent open={showSnackbar} {...snackbarProps} />}
      <Typography variant="h6" className={classes.heading}>
        Recalculate Scores
      </Typography>
      {(isLoading && <div></div>) ||
        (errorDetails && (
          <ErrorDisplay
            title="Something went wrong"
            description={errorDetails}
            noPadding={true}
          />
        )) || (
          <div>
            <FormControl fullWidth>
              <InputLabel id="market-select-label">Select Market</InputLabel>
              <Select
                labelId="market-select-label"
                value={selectedOption}
                label="Select Market"
                onChange={handleSelectChange}
              >
                {availableMarkets.map((market) => (
                  <MenuItem key={market} value={market}>
                    {market}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={handleButtonClick}
              style={{ marginTop: 20 }}
            >
              Recalculate Scores
            </Button>
          </div>
        )}
    </div>
  )
}

export default RecalculateScores

RecalculateScores.propTypes = {
  markets: PropTypes.array,
  errorDetails: PropTypes.string,
  isLoading: PropTypes.bool,
}
