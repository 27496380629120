import React from "react"
import { ResponsiveBar } from "@nivo/bar"
import PropTypes from "prop-types"

// Make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

const BarChartComponent = ({ data, displayActive }) => (
  <ResponsiveBar
    data={data}
    keys={
      displayActive
        ? [
            "Active",
            "Not Launched",
            "Hidden",
            "Paused",
            "Out of Business",
            "Blacklisted",
          ]
        : ["Not Launched", "Hidden", "Paused", "Out of Business", "Blacklisted"]
    }
    indexBy="country"
    groupMode="grouped"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: displayActive ? "symlog" : "linear" }}
    colors={
      displayActive
        ? ["#60cdbb", "#e9c1a0", "#d3d3d3", "#f1e15b", "#f4755f", "#222"]
        : ["#e9c1a0", "#d3d3d3", "#f1e15b", "#f4755f", "#222"]
    }
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "#38bcb2",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "#eed312",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: "fries",
        },
        id: "dots",
      },
      {
        match: {
          id: "sandwich",
        },
        id: "lines",
      },
    ]}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Market",
      legendPosition: "middle",
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Casinos",
      legendPosition: "middle",
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    legends={[
      {
        dataFrom: "keys",
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    animate={false}
    motionStiffness={90}
    motionDamping={15}
  />
)

export default BarChartComponent

BarChartComponent.propTypes = {
  data: PropTypes.array,
  displayActive: PropTypes.bool,
}
