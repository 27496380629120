import React from "react"
import RouterComponent from "./components/RouterComponent"
import { StateProvider } from "./state"
import { ThemeProvider } from "@mui/styles"
import { createTheme } from "@mui/material/styles"
import "./App.css"
import { Auth0Provider } from "@auth0/auth0-react"
import { createBrowserHistory } from "history"

export const history = createBrowserHistory()

const theme = createTheme({
  colors: {
    primary: "#323e94",
    drawer: {
      background: "#333",
      activeBackground: "#323e94",
      activeBorderRadius: "",
      activeForeground: "#fff !important",
      hoverText: "#fff",
      icon: "#999",
      text: "#a1a1a1",
      collapseFirstLevel: "#444",
      collapseSecondLevel: "#777",
    },
  },
})

function App() {
  const initialState = {
    appBarContext: {
      title: "",
      isLoading: false,
      saveEnabled: false,
      enableSearch: false,
      enableFilter: false,
      filterFields: undefined,
      activeFieldFilters: undefined,
      missingValueFilterFields: undefined,
      searchTerm: "",
      saveAction: undefined,
      backNavUrl: undefined,
      refreshAction: undefined,
      refreshActionTooltip: undefined,
      fields: [],
      toggleBulkEditAction: undefined,
      toggleBulkEditActionTooltip: undefined,
      enableChangelog: false,
      changelogClicked: undefined,
    },
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "changeAppBar":
        return {
          ...state,
          appBarContext: { ...state.appBarContext, ...action.newAppBar },
        }

      default:
        return state
    }
  }

  const onRedirectCallback = (appState) => {
    // Use the router's history module to replace the url
    history.replace(appState?.returnTo || window.location.pathname)
  }

  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const audience = process.env.REACT_APP_AUDIENCE

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
      }}
    >
      <StateProvider initialState={initialState} reducer={reducer}>
        <ThemeProvider theme={theme}>
          {process.env.AUTH0_DOMAIN}
          <RouterComponent history={history} />
        </ThemeProvider>
      </StateProvider>
    </Auth0Provider>
  )
}

export default App
