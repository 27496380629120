import { countryToFlag } from "../../helpers/GeneralHelper"
import { Fields } from "../"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import HomeIcon from "@mui/icons-material/Home"
import ListIcon from "@mui/icons-material/List"
import CasinoIcon from "@mui/icons-material/Casino"
import SettingsIcon from "@mui/icons-material/Settings"
import FlagIcon from "@mui/icons-material/Flag"
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"
import BusinessIcon from "@mui/icons-material/Business"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"

export const menu = [
  {
    name: "Home",
    link: "/",
    Icon: HomeIcon,
  },
  {
    name: "Affiliate Programs",
    link: "/root/affiliateprograms",
    Icon: BusinessCenterIcon,
    fields: Fields.affiliateProgramsRoot,
    allowCreate: true,
    allowDelete: true,
  },
  {
    name: "Affiliate Platforms",
    link: "/root/affiliateplatforms",
    Icon: SettingsIcon,
    fields: Fields.affiliatePlatformsRoot,
    allowCreate: true,
    allowDelete: true,
  },
  {
    name: "Casinos",
    Icon: CasinoIcon,
    items: [
      {
        name: "Casino Licenses",
        link: "/root/casinolicenses",
        Icon: VerifiedUserIcon,
        fields: Fields.casinoLicensesRoot,
        allowCreate: true,
        allowDelete: true,
      },
      {
        name: "Central Database",
        link: "/root/casinos/root",
        Icon: ListIcon,
        fields: Fields.casinosRoot,
        allowCreate: true,
        enableRefreshLookups: true,
        enableChangelog: true,
        editFormEmptyFieldsWarning: {
          title: "Just a reminder",
          content:
            "There are empty fields in this form. If they are intentionally left empty, simply click Continue to save.",
          ignoreFields: ["status_root"],
        },
      },
      {
        name: "Australia",
        Icon: () => countryToFlag("au"),
        items: [
          {
            name: "Country Database",
            link: "/root/casinos/au",
            Icon: ListIcon,
            fields: Fields.casinosCountry,
            marketLabel: "Australia",
            allowAdd: true,
            enableChangelog: true,
          },
          {
            name: "betting.site",
            link: "/bettingsite/casinos/au",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "Australia",
            allowAdd: true,
          },
          {
            name: "online.casino",
            link: "/onlinecasino/casinos/au",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "Australia",
            allowAdd: true,
          },
        ],
      },
      {
        name: "Canada",
        Icon: () => countryToFlag("ca"),
        items: [
          {
            name: "Country Database",
            link: "/root/casinos/ca",
            Icon: ListIcon,
            fields: Fields.casinosCountry,
            marketLabel: "Canada",
            allowAdd: true,
            enableChangelog: true,
          },
          {
            name: "betting.site",
            link: "/bettingsite/casinos/ca",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "Canada",
            allowAdd: true,
          },
          {
            name: "online.casino",
            link: "/onlinecasino/casinos/ca",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "Canada",
            allowAdd: true,
          },
        ],
      },
      {
        name: "India",
        Icon: () => countryToFlag("in"),
        items: [
          {
            name: "Country Database",
            link: "/root/casinos/in",
            Icon: ListIcon,
            fields: Fields.casinosCountry,
            marketLabel: "India",
            allowAdd: true,
            enableChangelog: true,
          },
          {
            name: "online.casino",
            link: "/onlinecasino/casinos/in",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "India",
            allowAdd: true,
          },
        ],
      },
      {
        name: "New Zeeland",
        Icon: () => countryToFlag("nz"),
        items: [
          {
            name: "Country Database",
            link: "/root/casinos/nz",
            Icon: ListIcon,
            fields: Fields.casinosCountry,
            marketLabel: "New Zeeland",
            allowAdd: true,
            enableChangelog: true,
          },
          {
            name: "online.casino",
            link: "/onlinecasino/casinos/nz",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "New Zeeland",
            allowAdd: true,
          },
        ],
      },
      {
        name: "South Africa",
        Icon: () => countryToFlag("za"),
        items: [
          {
            name: "Country Database",
            link: "/root/casinos/za",
            Icon: ListIcon,
            fields: Fields.casinosCountry,
            marketLabel: "South Africa",
            allowAdd: true,
            enableChangelog: true,
          },
          {
            name: "online.casino",
            link: "/onlinecasino/casinos/za",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "South Africa",
            allowAdd: true,
          },
        ],
      },
      {
        name: "United Kingdom",
        Icon: () => countryToFlag("gb"),
        items: [
          {
            name: "Country Database",
            link: "/root/casinos/uk",
            Icon: ListIcon,
            fields: Fields.casinosCountry,
            marketLabel: "United Kingdom",
            allowAdd: true,
            enableChangelog: true,
          },
          {
            name: "betting.site",
            link: "/bettingsite/casinos/uk",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "United Kingdom",
            allowAdd: true,
          },
          {
            name: "online.casino",
            link: "/onlinecasino/casinos/uk",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "United Kingdom",
            allowAdd: true,
          },
        ],
      },
      {
        name: "Rest of the World",
        Icon: FlagIcon,
        items: [
          {
            name: "Country Database",
            link: "/root/casinos/row",
            Icon: ListIcon,
            fields: Fields.casinosCountry,
            marketLabel: "Rest of the World",
            allowAdd: true,
            enableChangelog: true,
          },
          {
            name: "betting.site",
            link: "/bettingsite/casinos/row",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "Rest of the World",
            allowAdd: true,
          },
          {
            name: "online.casino",
            link: "/onlinecasino/casinos/row",
            Icon: ListIcon,
            fields: Fields.casinosSite,
            marketLabel: "Rest of the World",
            allowAdd: true,
          },
        ],
      },
    ],
  },
  {
    name: "Casino Platforms",
    link: "/root/casinoplatforms",
    Icon: SettingsIcon,
    fields: Fields.casinoPlatformsRoot,
    allowCreate: true,
    allowDelete: true,
  },
  {
    name: "Game Suppliers",
    Icon: PlayArrowIcon,
    items: [
      {
        name: "Game Suppliers",
        link: "/root/gamestudios",
        Icon: PlayArrowIcon,
        fields: Fields.gameStudiosRoot,
        allowCreate: true,
        allowDelete: true,
      },
      {
        name: "Game Supplier Licenses",
        link: "/root/gamestudiolicenses",
        Icon: VerifiedUserIcon,
        fields: Fields.gameStudioLicensesRoot,
        allowCreate: true,
        allowDelete: true,
      },
    ],
  },
  {
    name: "Licenses",
    link: "/root/licenses",
    Icon: VerifiedUserIcon,
    fields: Fields.licensesRoot,
    allowCreate: true,
    allowDelete: true,
  },
  {
    name: "Operators",
    link: "/root/operators",
    Icon: BusinessIcon,
    fields: Fields.operatorsRoot,
    allowCreate: true,
    allowDelete: true,
  },
  {
    name: "Payment Providers",
    link: "/root/paymentproviders",
    Icon: AccountBalanceIcon,
    fields: Fields.paymentProvidersRoot,
    allowCreate: true,
    allowDelete: true,
  },
  {
    divider: true,
  },
  {
    name: "Logout",
    Icon: ExitToAppIcon,
    logoutButton: true,
  },
]
