import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import Skeleton from "@mui/material/Skeleton"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

const useStyles = makeStyles((theme) => ({
  textSkeleton: {
    padding: "10px 0",
  },
}))

const FormSkeleton = (props) => {
  const classes = useStyles()

  const { rows } = props

  const skeletonRows = []

  for (let i = 0; i < rows; i++) {
    skeletonRows.push(
      <Grid item xs={3} key={"field-skeleton-" + i}>
        <Skeleton className={classes.textSkeleton} variant="text" />
      </Grid>
    )
  }

  return (
    <div>
      {" "}
      <Grid container spacing={4}>
        <Grid component={Typography} item xs={12} variant="h2">
          <Skeleton variant="text" />
        </Grid>
        {skeletonRows}
        <Grid component={Typography} item xs={12} variant="h2">
          <Skeleton variant="text" />
        </Grid>
        {skeletonRows}
      </Grid>
    </div>
  )
}

FormSkeleton.propTypes = {
  rows: PropTypes.number.isRequired,
}

export default FormSkeleton
