import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import ErrorDisplay from "../reusable/ErrorDisplay"
import PieChartComponent from "./PieChartComponent"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
  skeletonContainer: { padding: 40 },
  circleSkeleton: {
    height: 260,
    width: 260,
    margin: "0 auto",
  },
  heading: {
    fontSize: 16,
    color: "#575757",
  },
  subHeading: {
    fontSize: 12,
    color: "#575757",
  },
  chartContainer: {
    textAlign: "center",
  },
}))

const colors = [
  "#d3d3d3",
  "#E27D60",
  "#85cdca",
  "#E8A87C",
  "#C38D9E",
  "#938e94",
  "#7395ae",
  "#B1A296",
]

const CasinosPerMarket = (props) => {
  const { markets, errorDetails, isLoading } = props

  const classes = useStyles()

  const [chartData, setChartData] = useState([])

  useEffect(() => {
    const tempChartData = []
    for (let i = 0; i < markets.length; i++) {
      const market = markets[i]

      if (market.results && market.shortName !== "Central") {
        tempChartData.push({
          name: market.shortName,
          frequency: market.results.length,
          tooltipTitle: market.name + ": " + market.results.length + " casinos",
          color: colors[i],
        })
      }
    }
    setChartData(tempChartData)
  }, [markets])

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.heading}>
        Casinos by market
      </Typography>
      {(isLoading && (
        <div className={classes.skeletonContainer}>
          <Skeleton className={classes.circleSkeleton} variant="circle" />
        </div>
      )) ||
        (errorDetails && (
          <ErrorDisplay
            title="Something went wrong"
            description={errorDetails}
            noPadding={true}
          />
        )) || (
          <div className={classes.chartContainer}>
            <PieChartComponent width={300} height={300} data={chartData} />
          </div>
        )}
    </div>
  )
}

export default CasinosPerMarket

CasinosPerMarket.propTypes = {
  markets: PropTypes.array,
  errorDetails: PropTypes.string,
  isLoading: PropTypes.bool,
}
