import React from "react"
import Snackbar from "@mui/material/Snackbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

function SnackbarComponent(props) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      autoHideDuration={props.autoHideDuration}
      ContentProps={{
        classes: {
          root: props.class,
        },
      }}
      open={props.open}
      onClose={props.closeEvent}
      message={
        <div className={props.messageClass}>
          {props.icon}
          {props.message}
        </div>
      }
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={props.closeEvent}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  )
}

export default SnackbarComponent
