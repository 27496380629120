import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import { useAuth0 } from "@auth0/auth0-react"
import { getItem, getItems, postItem } from "../../helpers/ItemHelper"
import LinkStatusesTable from "./LinkStatusesTable"
import ErrorDisplay from "../reusable/ErrorDisplay"
import CheckIcon from "@mui/icons-material/Check"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import SnackbarComponent from "../reusable/SnackbarComponent"

const useStyles = makeStyles((theme) => ({
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
}))

const LinkStatuses = (props) => {
  const classes = useStyles()

  const { getAccessTokenSilently } = useAuth0()

  const [rows, setRows] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [errorDetails, setErrorDetails] = useState(undefined),
    [showSnackbar, setShowSnackbar] = useState(false),
    [snackbarProps, setSnackbarProps] = useState({}),
    [previouslySnoozedUrls, setPreviouslySnoozedUrls] = useState([]),
    [lastUpdated, setLastUpdated] = useState(undefined)

  const headCells = [
    {
      id: "item",
      numeric: false,
      disablePadding: true,
      label: "Casino",
      main: true,
    },
    { id: "location", disablePadding: false, label: "Location" },
    { id: "status", disablePadding: false, label: "Response" },
    { id: "url", disablePadding: false, label: "Url" },
  ]

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSnackbar(false)
  }

  const snoozeUrls = (urls, notes, callback) => {
    const newSnoozedUrls = urls.map((u) => {
      return {
        url: u,
        notes: notes || null,
      }
    })
    getAccessTokenSilently().then((token) => {
      postItem(
        token,
        "/root/stats/snoozedurls",
        newSnoozedUrls,
        false,
        [
          { name: "url", title: "Url" },
          { name: "notes", title: "Notes" },
        ],
        (results) => {
          if (results && !results.errno) {
            setShowSnackbar(true)
            setSnackbarProps({
              messageClass: classes.snackbarMessage,
              closeEvent: handleCloseSnackbar,
              message: newSnoozedUrls.length + " URLs has been marked as OK",
              icon: <CheckIcon />,
              autoHideDuration: 2000,
            })
            callback(true)
          } else {
            console.log("error: ", results)
            setShowSnackbar(true)
            setSnackbarProps({
              class: classes.errorSnackbar,
              messageClass: classes.snackbarMessage,
              closeEvent: handleCloseSnackbar,
              message: "Could not mark URLs as OK, something went wrong",
              icon: <ErrorOutlineIcon />,
              autoHideDuration: 2000,
            })
            callback(false)
          }
        }
      )
    })
  }

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getItem(
        token,
        "/root/stats/linkStatus",
        [
          { name: "name", title: "Name" },
          { name: "value", title: "Value" },
        ],
        (results) => {
          if (results && results.value) {
            const jsonResults = JSON.parse(results.value).filter(
              (r) => r.status !== 200
            )
            setRows(jsonResults)

            if (results.timestamp) {
              const timestamp = new Date(results.timestamp)
              setLastUpdated(timestamp.toLocaleString())
            }

            getItems(token, "/root/stats/snoozedurls", (results) => {
              setIsLoading(false)
              if (results && Array.isArray(results)) {
                setPreviouslySnoozedUrls(results.map((r) => r.url))
              } else {
                setErrorDetails("Could not load snoozed tracking url alerts")
              }
            })
          } else {
            setErrorDetails("Could not load tracking url statuses")
          }
        }
      )
    })
  }, [getAccessTokenSilently])

  return (
    <div className={classes.root}>
      {showSnackbar && <SnackbarComponent open={showSnackbar} {...snackbarProps} />}
      {(errorDetails && (
        <ErrorDisplay
          title="Something went wrong"
          description={errorDetails}
          noPadding={true}
        />
      )) || (
        <>
          <LinkStatusesTable
            headCells={headCells}
            rows={rows}
            snoozeUrls={snoozeUrls}
            previouslySnoozedUrls={previouslySnoozedUrls}
            lastUpdated={lastUpdated}
            isLoading={isLoading}
          />
        </>
      )}
    </div>
  )
}

export default LinkStatuses
