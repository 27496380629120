import React, { forwardRef } from "react"
import ListItem from "@mui/material/ListItem"
import { NavLink } from "react-router-dom"
import PropTypes from "prop-types"

const AppMenuItemComponent = (props) => {
  const { className, onClick, link, fields, children } = props

  // If link is not set return the ordinary ListItem
  if (!link || typeof link !== "string") {
    return (
      <ListItem button className={className} onClick={onClick}>
        {children}
      </ListItem>
    )
  }

  const isActive = (match, location) => {
    if (!match) {
      return false
    }

    // Trim last url segment if numeric to allow active highlighting when editing items
    const trimmedLocation = location.pathname
      .split("?")[0]
      .split("#")[0]
      .split(/(\/\d*)$/)[0]
      .replace(/\/new$/, "")

    return (
      match.url === trimmedLocation || (match.url === "/" && trimmedLocation === "")
    )
  }

  // Return a LitItem with a link component
  return (
    <ListItem
      button
      className={className}
      // eslint-disable-next-line react/display-name
      component={forwardRef((props, ref) => (
        <NavLink isActive={isActive} {...props} innerRef={ref} />
      ))}
      to={{ pathname: link, fields }}
    >
      {children}
    </ListItem>
  )
}

AppMenuItemComponent.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
  fields: PropTypes.array,
  children: PropTypes.element,
}

export default AppMenuItemComponent
