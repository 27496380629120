import React, { useState } from "react"
import {
  Router,
  Switch,
  Route,
  Prompt,
  Redirect,
  useLocation,
} from "react-router-dom"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import PropTypes from "prop-types"
import AppBarComponent from "./appbar/AppBarComponent"
import HomeScreen from "./home/HomeScreen"
import ItemTable from "./itemtable/ItemTable"
import EditForm from "./editform/EditForm"
import { useStateValue } from "../state"
import { useEffect } from "react"

function RouterComponent(props) {
  const { history } = props

  const [isBlocking, setIsBlocking] = useState(false)
  const [{ appBarContext }] = useStateValue()

  const { pathname } = useLocation()

  useEffect(() => {
    setIsBlocking(appBarContext.saveEnabled)
  }, [appBarContext])

  return (
    <Router history={history}>
      <AppBarComponent>
        <>
          <Prompt
            when={isBlocking}
            message={() =>
              "You have unsaved changes. Are you sure you want to navigate and loose them?"
            }
          />
          <Switch>
            <Route
              path="/:url*(/+)"
              render={() => <Redirect to={pathname.slice(0, -1)} />}
            />
            <Route path="/:level/:type/:market/:id">
              <EditForm />
            </Route>
            <Route path="/:level/:type/:id(new|[0-9]+)">
              <EditForm />
            </Route>
            <Route path="/:level/:type/:market">
              <ItemTable />
            </Route>
            <Route path="/:level/:type">
              <ItemTable />
            </Route>
            <Route path="/">
              <HomeScreen />
            </Route>
          </Switch>
        </>
      </AppBarComponent>
    </Router>
  )
}

RouterComponent.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withAuthenticationRequired(RouterComponent)
