import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import ErrorDisplay from "../reusable/ErrorDisplay"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import TableSkeleton from "../reusable/TableSkeleton"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TablePagination from "@mui/material/TablePagination"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Popover from "@mui/material/Popover"
import Tooltip from "@mui/material/Tooltip"
import DateRangeIcon from "@mui/icons-material/DateRange"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { Constants } from "../../config"
import { countryToFlag } from "../../helpers/GeneralHelper"
import FlagIcon from "@mui/icons-material/Flag"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment/moment"
import TableSortLabel from "@mui/material/TableSortLabel"

// Filter popover component
const useFilterPopoverComponentStyles = makeStyles((theme) => ({
  popover: { padding: 20 },
  dateInput: {
    margin: "0 10px",
  },
  clearButtonArea: {
    textAlign: "right",
    margin: "20px 0 0",
  },
}))

function FilterPopover(props) {
  const classes = useFilterPopoverComponentStyles()

  const {
    filterPopoverAnchorEl,
    setfilterPopoverAnchorEl,
    startDate,
    handleStartDateChange,
    endDate,
    handleEndDateChange,
    handleClearClicked,
  } = props

  return (
    <Popover
      id={"filter-popover"}
      open={Boolean(filterPopoverAnchorEl)}
      anchorEl={filterPopoverAnchorEl}
      onClose={(e) => setfilterPopoverAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className={classes.popover}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment.utc}
        >
          <DatePicker
            className={classes.dateInput}
            label="Start month"
            views={["year", "month"]}
            onChange={(value) => {
              handleStartDateChange(value)
            }}
            value={moment(startDate)}
          />
          <DatePicker
            className={classes.dateInput}
            label="End month"
            views={["year", "month"]}
            onChange={(value) => {
              handleEndDateChange(value)
            }}
            value={moment(endDate)}
          />
        </LocalizationProvider>
        <div className={classes.clearButtonArea}>
          <Button color="primary" onClick={handleClearClicked}>
            Clear
          </Button>
        </div>
      </div>
    </Popover>
  )
}

FilterPopover.propTypes = {
  filterPopoverAnchorEl: PropTypes.object,
  setfilterPopoverAnchorEl: PropTypes.func,
  startDate: PropTypes.object,
  handleStartDateChange: PropTypes.func,
  endDate: PropTypes.object,
  handleEndDateChange: PropTypes.func,
  handleClearClicked: PropTypes.func,
}

// Overview Table component
const useOverviewTableStyles = makeStyles((theme) => ({
  table: { "& td, & th": { fontSize: 12 } },
}))

function OverviewTable(props) {
  const classes = useOverviewTableStyles()

  const { rows, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    props

  const config = Constants.homeScreen.fieldOverviewSettings
  const fieldsToDisplay = config.fieldsToDisplay

  // Initialize state for sortField and sortDirection
  const [sortField, setSortField] = useState("end_date")
  const [sortDirection, setSortDirection] = useState("desc")

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortDirection === "asc"
    setSortDirection(isAsc ? "desc" : "asc")
    setSortField(field)
  }

  // Sort rows
  const sortedRows = [...rows].sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return sortDirection === "asc" ? -1 : 1
    }
    if (a[sortField] > b[sortField]) {
      return sortDirection === "asc" ? 1 : -1
    }
    return 0
  })

  return (
    <>
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="Fields overview table"
        >
          <TableHead>
            <TableRow>
              {fieldsToDisplay
                .filter((f) => !f.jsonArray)
                .map((f) => (
                  <TableCell
                    key={f.name}
                    align={f.mainField ? "left" : "right"}
                    sortDirection={sortField === f.name ? sortDirection : false}
                  >
                    <TableSortLabel
                      active={sortField === f.name}
                      direction={sortField === f.name ? sortDirection : "asc"}
                      onClick={() => handleSortRequest(f.name)}
                    >
                      {f.title}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={config.getKey(row)}>
                  {fieldsToDisplay
                    .filter((f) => !f.jsonArray)
                    .map((f) => (
                      <TableCell
                        key={f.name}
                        component={f.mainField && "th"}
                        scope={f.mainField && "row"}
                        align={f.mainField ? "left" : "right"}
                      >
                        {row[f.prefixFlagField] ? (
                          row[f.prefixFlagField] === "ROW" ? (
                            <FlagIcon
                              style={{ color: "#d3d3d3", fontSize: "16px" }}
                            />
                          ) : (
                            `${countryToFlag(
                              row[f.prefixFlagField] &&
                                row[f.prefixFlagField].toLowerCase()
                            )} `
                          )
                        ) : (
                          ""
                        )}
                        {f.customFormat ? f.customFormat(row, f.name) : row[f.name]}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

OverviewTable.propTypes = {
  rows: PropTypes.array.isRequired,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
  topArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  heading: {
    fontSize: 16,
    color: "#575757",
  },
  filterButtonActive: {},
  buttonGroup: {
    margin: "0 10px",
  },
}))

// Main component
const FieldOverview = (props) => {
  const classes = useStyles()

  const { markets, errorDetails, isLoading } = props

  const config = Constants.homeScreen.fieldOverviewSettings
  const startDateFieldName =
    config.fieldsToDisplay.filter((f) => f.filterStartDate).length > 0
      ? config.fieldsToDisplay.filter((f) => f.filterStartDate)[0]
      : false
  const endDateFieldName =
    config.fieldsToDisplay.filter((f) => f.filterEndDate).length > 0
      ? config.fieldsToDisplay.filter((f) => f.filterEndDate)[0]
      : false

  const requiredFields = []
  config.fieldsToDisplay.map((f) => f.required && requiredFields.push(f.name))

  const [availableMarkets, setAvailableMarkets] = useState([]),
    [currentMarket, setCurrentMarket] = useState(false),
    [tableData, setTableData] = useState(false),
    [filteredTableData, setFilteredTableData] = useState(false),
    [page, setPage] = useState(0),
    [rowsPerPage, setRowsPerPage] = useState(10),
    [filterPopoverAnchorEl, setfilterPopoverAnchorEl] = useState(null),
    [filterStartDate, setFilterStartDate] = useState(null),
    [filterEndDate, setFilterEndDate] = useState(null),
    [tabFilter, setTabFilter] = useState(null)

  const tabs = ["Active", "Upcoming", "Expired"]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    if (Array.isArray(markets) && markets.length > 0 && !tableData) {
      let tempTableData = []
      const tempAvailableMarkets = []
      for (let i = 0; i < markets.length; i++) {
        const results = markets[i].results
        const relevantItems = results.filter((r) => {
          for (let j = 0; j < requiredFields.length; j++) {
            if (
              r.hasOwnProperty(requiredFields[j]) &&
              r[requiredFields[j]] !== "[]" &&
              r[requiredFields[j]] !== null
            ) {
              if (tempAvailableMarkets.indexOf(markets[i].shortName) < 0)
                tempAvailableMarkets.push(markets[i].shortName)
              return true
            }
          }
          return false
        })
        const formattedItems = []
        relevantItems.forEach((item) => {
          const manualPosArray = JSON.parse(item.manual_positions)
          manualPosArray.forEach((mp) => {
            formattedItems.push({
              id: item.id,
              internal_name: item.internal_name,
              name: item.name,
              _market: markets[i].shortName,
              position: mp.position,
              start_date: mp.start_date,
              end_date: mp.end_date,
            })
          })
        })
        tempTableData = tempTableData.concat(formattedItems)
      }
      tempTableData.sort((a, b) => {
        return b.end_date.localeCompare(a.end_date)
      })
      setAvailableMarkets(tempAvailableMarkets)
      setTableData(tempTableData)
      setFilteredTableData(tempTableData)
    }
  }, [markets, tableData, requiredFields, availableMarkets])

  useEffect(() => {
    handleChangePage(undefined, 0)
    if (Array.isArray(tableData)) {
      const today = moment().startOf("day").format("YYYY-MM-DD")
      const tempTableData = tableData.filter((d) => {
        const startDate = d[startDateFieldName.name]
        const endDate = d[endDateFieldName.name]

        const filterStart = filterStartDate
          ? filterStartDate.format("YYYY-MM-DD")
          : false
        const filterEnd = filterEndDate
          ? filterEndDate.endOf("month").format("YYYY-MM-DD")
          : false

        // Check if the current data item falls within the active, upcoming, or expired category
        const isActive =
          startDate && endDate && startDate <= today && endDate >= today
        const isUpcoming = startDate && startDate > today
        const isExpired = endDate && endDate < today

        return (
          (!currentMarket || d._market === currentMarket) &&
          (!filterStart || !startDate || filterStart <= endDate) &&
          (!filterEnd || !endDate || filterEnd >= startDate) &&
          (tabFilter === "Active"
            ? isActive
            : tabFilter === "Upcoming"
            ? isUpcoming
            : tabFilter === "Expired"
            ? isExpired
            : true)
        )
      })

      setFilteredTableData(tempTableData)
    }
  }, [
    filterStartDate,
    filterEndDate,
    tableData,
    currentMarket,
    startDateFieldName,
    endDateFieldName,
    tabFilter,
  ])

  return (
    <div className={classes.root}>
      <div className={classes.topArea}>
        <Typography variant="h6" className={classes.heading}>
          {config.title}
        </Typography>
        <div>
          <ButtonGroup
            aria-label="Scope picker button group"
            className={classes.buttonGroup}
          >
            {availableMarkets.map((market) => (
              <Button
                key={market}
                color={currentMarket === market ? "primary" : "inherit"}
                onClick={() =>
                  setCurrentMarket(market === currentMarket ? false : market)
                }
              >
                {market}
              </Button>
            ))}
          </ButtonGroup>
          <ButtonGroup aria-label="Time Frames Tabs">
            {tabs.map((tab) => (
              <Button
                key={tab}
                color={tabFilter === tab ? "primary" : "inherit"}
                onClick={() => setTabFilter(tab === tabFilter ? false : tab)}
              >
                {tab}
              </Button>
            ))}
          </ButtonGroup>
          {config.enableDateFilter && (
            <Button
              onClick={(e) => setfilterPopoverAnchorEl(e.currentTarget)}
              color={filterStartDate || filterEndDate ? "primary" : "inherit"}
            >
              <Tooltip title="Filter items">
                <DateRangeIcon />
              </Tooltip>
            </Button>
          )}
          <FilterPopover
            filterPopoverAnchorEl={filterPopoverAnchorEl}
            setfilterPopoverAnchorEl={setfilterPopoverAnchorEl}
            startDate={filterStartDate}
            endDate={filterEndDate}
            handleStartDateChange={setFilterStartDate}
            handleEndDateChange={setFilterEndDate}
            handleClearClicked={() => {
              setFilterStartDate(null)
              setFilterEndDate(null)
            }}
          />
        </div>
      </div>
      {(isLoading && <TableSkeleton rows={5} />) ||
        (errorDetails && (
          <ErrorDisplay
            title="Something went wrong"
            description={errorDetails}
            noPadding={true}
          />
        )) ||
        (Array.isArray(filteredTableData) && filteredTableData.length > 0 && (
          <OverviewTable
            rows={filteredTableData}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )) || <div className={classes.chartContainer}>{config.emptyMessage}</div>}
    </div>
  )
}

export default FieldOverview

FieldOverview.propTypes = {
  markets: PropTypes.array,
  errorDetails: PropTypes.string,
  isLoading: PropTypes.bool,
}
