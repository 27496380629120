/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import TextField from "@mui/material/TextField"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment/moment"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  inputArea: {
    display: "flex",
    flexDirection: "column",
    marginRight: 200,
    "& > div": {
      marginTop: 10,
    },
    "& div:first-child": {
      marginTop: 0,
    },
  },
  listArea: {},
  submitButton: {
    marginTop: 20,
  },
  removeButton: {
    padding: 0,
    color: "#9b9797",
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 250,
    marginTop: 10,
  },
  itemPosition: { fontSize: 24, fontWeight: "bold" },
  itemDates: {},
}))

const ListEditor = ({ value, onChange }) => {
  const classes = useStyles()

  const [positions, setPositions] = useState(value ? JSON.parse(value) : []),
    [input, setInput] = useState({})

  const positionInputChanged = (event) => {
    const value = event.target.value
    setInput({ ...input, position: value ? parseInt(value) : "" })
  }

  const dateInputChanged = (value, attribute) => {
    setInput({ ...input, [attribute]: value.toISOString().split("T")[0] })
  }

  const addListItem = () => {
    setPositions((prevData) => [...prevData, input])
    setInput({})
  }

  const removeListItem = (item) => {
    setPositions((prevData) => [...prevData.filter((p) => p !== item)])
  }

  useEffect(() => {
    onChange(JSON.stringify(positions))
  }, [positions, onChange])

  return (
    <div className={classes.root}>
      <div className={classes.inputArea}>
        <TextField
          label="Position"
          type="number"
          step="1"
          size="small"
          value={input?.position || ""}
          onChange={positionInputChanged}
        />
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment.utc}
        >
          <DatePicker
            label={"Start date"}
            views={["year", "month", "day"]}
            inputProps={{
              "aria-label": "Start date",
            }}
            onChange={(value) => {
              dateInputChanged(value, "start_date")
            }}
            value={moment(input.start_date || null)}
          />
          <DatePicker
            label={"End date"}
            views={["year", "month", "day"]}
            inputProps={{
              "aria-label": "End date",
            }}
            onChange={(value) => {
              dateInputChanged(value, "end_date")
            }}
            value={moment(input.end_date || null)}
          />
        </LocalizationProvider>
        <Button
          className={classes.submitButton}
          onClick={() => addListItem()}
          disabled={!input.position || !input.start_date || !input.end_date}
        >
          Add position
        </Button>
      </div>
      <div className={classes.listArea}>
        {positions && positions.length > 0 ? (
          <>
            <h3 style={{ marginTop: 0, textAlign: "center" }}>Positions</h3>
            <hr />
            {positions
              .sort((a, b) => {
                const keyA = new Date(a.start_date),
                  keyB = new Date(b.start_date)
                if (keyA < keyB) return -1
                if (keyA > keyB) return 1
                return 0
              })
              .map((p, i) => (
                <div key={i} className={classes.item}>
                  <div className={classes.itemPosition}>{p.position}</div>
                  <div className={classes.itemDates}>
                    {p.start_date} <i style={{ margin: "0 5px" }}>to</i> {p.end_date}
                  </div>
                  <IconButton
                    className={classes.removeButton}
                    aria-label="Remove"
                    onClick={() => removeListItem(p)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
          </>
        ) : (
          <>
            <p>No manual positions have been added</p>
            <p>
              After adding manual positions, <b>dont forget</b> to click the save
              button
            </p>
          </>
        )}
      </div>
    </div>
  )
}

ListEditor.defaultProps = {
  value: "",
  onChange: () => {},
}

ListEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default ListEditor
