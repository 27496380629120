import React from "react"
import PropTypes from "prop-types"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "150px 0",
  },
  rootNoPadding: {
    padding: 0,
  },
  errorIcon: {
    fontSize: "90px",
    color: "#d3d3d3",
  },
  title: {
    fontSize: "16px",
  },
}))

function ErrorDisplay(props) {
  const { title, description, noPadding } = props

  const classes = useStyles()

  return (
    <div
      className={`${classes.root}${noPadding ? " " + classes.rootNoPadding : ""}`}
    >
      <ErrorOutlineIcon className={classes.errorIcon} />
      <p className={classes.title}>{title}</p>
      <small>{description}</small>
    </div>
  )
}

ErrorDisplay.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  noPadding: PropTypes.bool,
}

export default ErrorDisplay
