import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import ErrorDisplay from "../reusable/ErrorDisplay"
import BarChartComponent from "./BarChartComponent"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import PropTypes from "prop-types"
import { Constants } from "../../config"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  errorSnackbar: {
    backgroundColor: "#f44336",
    fontWeight: "500",
  },
  snackbarMessage: {
    "& > svg": {
      marginRight: 10,
    },
    display: "flex",
    alignItems: "center",
  },
  skeletonContainer: { padding: 40 },
  rectSkeleton: {
    height: 340,
    width: "100%",
    margin: "0 auto",
  },
  topArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    fontSize: 16,
    color: "#575757",
  },
  graphSwitch: {
    color: "#575757",
    "& span": { fontSize: 12 },
  },
  chartContainer: {
    textAlign: "center",
    height: 400,
  },
}))

const getStatusCounts = (items, statusField) => {
  return {
    active: items.filter((c) => c[statusField].toLowerCase() === "1. active").length,
    notLaunched: items.filter(
      (c) => c[statusField].toLowerCase() === "2. not launched"
    ).length,
    hidden: items.filter((c) => c[statusField].toLowerCase() === "3. hidden").length,
    paused: items.filter((c) => c[statusField].toLowerCase() === "4. paused").length,
    outOfBusiness: items.filter(
      (c) => c[statusField].toLowerCase() === "5. out of business"
    ).length,
    blacklisted: items.filter(
      (c) => c[statusField].toLowerCase() === "6. blacklisted"
    ).length,
  }
}

const CasinoStatuses = (props) => {
  const { markets, errorDetails, isLoading } = props

  const classes = useStyles()

  const [chartData, setChartData] = useState([]),
    [displayActive, setDisplayActive] = useState(false)

  useEffect(() => {
    const tempChartData = []
    for (let i = 0; i < markets.length; i++) {
      const market = markets[i]
      const statusField =
        market.shortName === "Central"
          ? Constants.statusFields[0].name
          : Constants.statusFields[1].name
      const { active, notLaunched, hidden, paused, outOfBusiness, blacklisted } =
        getStatusCounts(market.results, statusField)

      tempChartData.push({
        country: market.shortName === "Central" ? market.name : market.shortName,
        Active: displayActive ? active : null,
        "Not Launched": notLaunched,
        Hidden: hidden,
        Paused: paused,
        "Out of Business": outOfBusiness,
        Blacklisted: blacklisted,
      })
    }
    setChartData(tempChartData)
  }, [markets, displayActive])

  return (
    <div className={classes.root}>
      <div className={classes.topArea}>
        <Typography variant="h6" className={classes.heading}>
          Casinos by market and status
        </Typography>
        <FormGroup row>
          <FormControlLabel
            className={classes.graphSwitch}
            control={
              <Switch
                checked={displayActive}
                onChange={() => setDisplayActive(!displayActive)}
                name="display-active"
                color="primary"
              />
            }
            label="Display active"
            labelPlacement="start"
            disabled={isLoading || errorDetails}
            size="small"
          />
        </FormGroup>
      </div>
      {(isLoading && (
        <div className={classes.skeletonContainer}>
          <Skeleton className={classes.rectSkeleton} variant="rect" />
        </div>
      )) ||
        (errorDetails && (
          <ErrorDisplay
            title="Something went wrong"
            description={errorDetails}
            noPadding={true}
          />
        )) || (
          <div className={classes.chartContainer}>
            <BarChartComponent data={chartData} displayActive={displayActive} />
          </div>
        )}
    </div>
  )
}

export default CasinoStatuses

CasinoStatuses.propTypes = {
  markets: PropTypes.array,
  errorDetails: PropTypes.string,
  isLoading: PropTypes.bool,
}
