import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import clsx from "clsx"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import TextField from "@mui/material/TextField"
import ScheduleIcon from "@mui/icons-material/Schedule"
import TableSkeleton from "../reusable/TableSkeleton"

const descendingComparator = (a, b, orderBy) => {
  const aTerm =
    a[orderBy] && typeof a[orderBy] === "string"
      ? a[orderBy].toLowerCase()
      : a[orderBy].toString()
  const bTerm =
    b[orderBy] && typeof b[orderBy] === "string"
      ? b[orderBy].toLowerCase()
      : b[orderBy].toString()

  if (bTerm < aTerm) {
    return -1
  }
  if (bTerm > aTerm) {
    return 1
  }

  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    headCells,
    numSelected,
    rowCount,
    onSelectAllClick,
    onRequestSort,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  onSelectAllClick: PropTypes.func,
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          backgroundColor: theme.palette.secondary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  headingItemsSelected: {
    flex: "1 1 100%",
  },
  headingArea: {
    width: "100%",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 0, 0),
  },
  heading: {
    fontSize: 16,
    color: "#575757",
  },
  subHeading: {
    fontSize: 12,
    color: "#575757",
  },
  noWarningsContainer: {
    margin: theme.spacing(2, 0),
    display: "block",
  },
  noWarningsIcon: {
    color: "green",
    verticalAlign: "middle",
    margin: "-2px 5px 0 0",
    fontSize: "1.3rem",
  },
  snoozeUrlNote: {
    width: 400,
    marginRight: 20,
  },
  lastUpdatedIcon: {
    verticalAlign: "middle",
    marginTop: -2,
    fontSize: "1.2rem",
  },
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const {
    numSelected,
    onSelectedItemsActionClick,
    visibleRowsLength,
    notes,
    onHandleNotesChange,
    lastUpdated,
    isLoading,
  } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.headingItemsSelected}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div className={classes.headingArea}>
          <div className={classes.headingContainer}>
            <Typography variant="h6" className={classes.heading}>
              Tracking URL Checker
            </Typography>
            <Typography className={classes.subHeading} variant="subtitle1">
              {!isLoading && (
                <>
                  <ScheduleIcon className={classes.lastUpdatedIcon} size="small" />{" "}
                  {"  "} Last updated {lastUpdated}
                </>
              )}
            </Typography>
          </div>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.subHeading}
          >
            {visibleRowsLength > 0
              ? "The following tracking URLs have unusual responses"
              : !isLoading && (
                  <span
                    className={visibleRowsLength < 1 && classes.noWarningsContainer}
                  >
                    <CheckCircleIcon className={classes.noWarningsIcon} />
                    No new tracking URL warnings
                  </span>
                )}
          </Typography>
        </div>
      )}

      {numSelected > 0 && (
        <>
          <TextField
            className={classes.snoozeUrlNote}
            label="Notes (optional)"
            value={notes}
            onChange={onHandleNotesChange}
            size="small"
            color="secondary"
          />
          <Tooltip title="Mark as OK">
            <IconButton aria-label="Mark as OK" onClick={onSelectedItemsActionClick}>
              <CheckCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectedItemsActionClick: PropTypes.func,
  visibleRowsLength: PropTypes.number,
  notes: PropTypes.string,
  onHandleNotesChange: PropTypes.func,
  lastUpdated: PropTypes.string,
  isLoading: PropTypes.bool,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    "& td, & th": {
      fontSize: 12,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}))

export default function LinkStatusesTable(props) {
  const {
    headCells,
    rows,
    snoozeUrls,
    previouslySnoozedUrls,
    lastUpdated,
    isLoading,
  } = props

  const classes = useStyles()

  const [order, setOrder] = useState("asc"),
    [orderBy, setOrderBy] = useState("status"),
    [page, setPage] = useState(0),
    [visibleRows, setVisibleRows] = useState([]),
    [rowsPerPage, setRowsPerPage] = useState(5),
    [selected, setSelected] = useState([]),
    [snoozedUrls, setSnoozedUrls] = useState([]),
    [snoozeUrlNotes, setSnoozeUrlNotes] = useState("")

  const dense = true

  const handleSelectedItemsActionClick = () => {
    const newSnoozedUrls = selected.map((s) => s.url)
    setSnoozedUrls([...snoozedUrls, ...newSnoozedUrls])

    snoozeUrls(newSnoozedUrls, snoozeUrlNotes, (success) => {
      if (success) {
        setSelected([])
        setSnoozeUrlNotes("")
      }
    })
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = visibleRows.map((n) => n)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    setVisibleRows(rows.filter((r) => snoozedUrls.indexOf(r.url) === -1))
  }, [rows, snoozedUrls])

  useEffect(() => {
    setSnoozedUrls(previouslySnoozedUrls)
  }, [previouslySnoozedUrls])

  const isSelected = (name) => selected.indexOf(name) !== -1

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, visibleRows.length - page * rowsPerPage)

  const indexColumn = headCells.filter((h) => h.main)[0]

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        onSelectedItemsActionClick={handleSelectedItemsActionClick}
        visibleRowsLength={visibleRows.length}
        notes={snoozeUrlNotes}
        lastUpdated={lastUpdated}
        onHandleNotesChange={(event) => setSnoozeUrlNotes(event.target.value)}
        isLoading={isLoading}
      />
      {(isLoading && <TableSkeleton rows={5} />) ||
        (visibleRows.length > 0 && (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={visibleRows.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {stableSort(visibleRows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row)
                      const labelId = `enhanced-table-checkbox-${index}`
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          onClick={(event) => handleClick(event, row)}
                          tabIndex={-1}
                          key={row[indexColumn.id] + "-" + index}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          {headCells.map((h) => {
                            return h.main ? (
                              <TableCell
                                key={"table-cell-" + h.id}
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row[h.id]}
                              </TableCell>
                            ) : (
                              <TableCell key={"table-cell-" + h.id} align={h.align}>
                                {row[h.id]}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={visibleRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ))}
    </div>
  )
}

LinkStatusesTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  snoozeUrls: PropTypes.func.isRequired,
  previouslySnoozedUrls: PropTypes.array,
  lastUpdated: PropTypes.string,
  isLoading: PropTypes.bool,
}
