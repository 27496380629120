import React from "react"
import { Options } from "../"
import InfoIcon from "@mui/icons-material/Info"
import ContactsIcon from "@mui/icons-material/Contacts"
import CasinoIcon from "@mui/icons-material/Casino"
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"
import ContactSupportIcon from "@mui/icons-material/ContactSupport"
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin"
import PaidIcon from "@mui/icons-material/Paid"
import SyncIcon from "@mui/icons-material/Sync"
import GradeIcon from "@mui/icons-material/Grade"
import StarsIcon from "@mui/icons-material/Stars"
import MoneyIcon from "@mui/icons-material/Money"
import PersonIcon from "@mui/icons-material/Person"
import ReceiptIcon from "@mui/icons-material/Receipt"
import StoreIcon from "@mui/icons-material/Store"
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import GamesIcon from "@mui/icons-material/Games"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import RollerSkatingIcon from "@mui/icons-material/RollerSkating"
import InputAdornment from "@mui/material/InputAdornment"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"

export const Fields = {
  casinosRoot: [
    {
      name: "internal_name",
      title: "Internal name",
      hidden: true,
      internalNameField: true,
    },
    {
      headingTitle: "Contact information",
      headingIcon: <ContactsIcon />,
    },
    {
      name: "name",
      title: "Name",
      component: "th",
      scope: "row",
      displayInBrowse: true,
      mainTableColumn: true,
      required: true,
      nameField: true,
    },
    {
      name: "facebook_url",
      title: "Facebook url",
    },
    {
      name: "instagram_url",
      title: "Instagram url",
    },
    {
      name: "twitter_url",
      title: "Twitter url",
    },
    {
      name: "email_address",
      title: "Email address",
    },
    {
      name: "phone_number",
      title: "Phone number",
      inputProps: { validatephone: "true" },
      errorText: "Phone numbers must begin with + and be numeric after that",
    },
    {
      name: "website_url",
      title: "Website url",
    },
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "tracking_url",
      title: "Tracking url",
      displayInBrowse: true,
    },
    {
      name: "tracking_url_exclusive",
      title: "Exclusive tracking url",
      displayInBrowse: true,
    },
    {
      name: "tc_url",
      title: "T&C url",
    },
    {
      name: "established",
      title: "Established",
      type: "date",
      datePickerViews: ["year", "month"],
      inputLabelProps: {
        shrink: true,
      },
    },
    {
      name: "score",
      title: "Base score",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
        max: 10,
      },
      errorText: "Must be a value between 0 and 10",
      helperText: "For sites without calculated score",
    },
    {
      name: "status_root",
      title: "Status",
      type: "select",
      options: Options.statuses,
    },
    { headingTitle: "Casino information", headingIcon: <CasinoIcon /> },
    {
      name: "operator",
      title: "Operator",
      type: "autocomplete",
      optionsApi: "/root/operators/root",
      gridMd: 6,
    },
    {
      name: "affiliate_program",
      title: "Affiliate program",
      type: "autocomplete",
      optionsApi: "/root/affiliateprograms/root",
      gridMd: 6,
    },
    {
      name: "casino_platform",
      title: "Casino platform",
      type: "autocomplete",
      optionsApi: "/root/casinoplatforms/root",
      gridMd: 6,
    },
    {
      name: "products",
      title: "Products",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.products,
      gridMd: 6,
    },
    {
      name: "game_studios",
      title: "Game studios",
      type: "autocomplete",
      multiple: true,
      json: true,
      optionsApi: "/root/gamestudios/root",
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    {
      name: "languages",
      title: "Languages",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.languages,
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    {
      name: "restricted_countries",
      title: "Restricted countries",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.countries,
      selectAll: true,
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    {
      name: "restricted_regions",
      title: "Restricted regions",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.regions,
      groupBy: (option) => option.country,
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    {
      name: "currencies_fiat",
      title: "Currencies (FIAT)",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.currencies,
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    {
      name: "vpn_allowed",
      title: "Allows VPN gaming",
      type: "boolean",
    },
    {
      name: "no_kyc",
      title: "No KYC Casino",
      type: "boolean",
    },
    { headingTitle: "Crypto Information", headingIcon: <CurrencyBitcoinIcon /> },
    {
      name: "crypto_currencies",
      title: "Crypto currencies",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.cryptoCurrencies,
      gridXs: 6,
      gridSm: 6,
      gridMd: 6,
    },
    {
      name: "crypto_payment_providers",
      title: "Crypto payment providers",
      type: "autocomplete",
      optionsApi: "/root/paymentproviders/root",
      multiple: true,
      json: true,
      gridMd: 6,
      customFilter: (data) => {
        return data.filter((d) => d.type && d.type === "Cryptocurrency")
      },
    },
    { headingTitle: "Crypto bonus", headingIcon: <CurrencyBitcoinIcon /> },
    {
      name: "dep_crypto_currency",
      title: "Dep Crypto - Currency",
      type: "autocomplete",
      json: true,
      options: Options.cryptoCurrencies,
      gridXs: 12,
      gridSm: 6,
      gridMd: 9,
    },
    {
      name: "dep_crypto_fiat",
      title: "Dep Crypto - FIAT",
      type: "boolean",
      gridXs: 12,
      gridSm: 6,
      gridMd: 3,
    },
    {
      name: "dep_crypto_percentage",
      title: "Dep Crypto - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "dep_crypto_max_bonus",
      title: "Dep Crypto - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep_crypto_wager_requirements",
      title: "Dep Crypto - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep_crypto_expiration_time",
      title: "Dep Crypto - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep_crypto_max_bet",
      title: "Dep Crypto - Max bet",
    },
    {
      name: "dep_crypto_max_withdrawal",
      title: "Dep Crypto - Max withdrawal",
    },
    {
      name: "dep_crypto_min_deposit",
      title: "Dep Crypto - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep_crypto_bonus_code",
      title: "Dep Crypto - Bonus code",
    },
    {
      name: "dep_crypto_wager_requirements_deposit",
      title: "Dep Crypto - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "dep_crypto_wager_requirements_bonus",
      title: "Dep Crypto - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "dep_crypto_forfeitable",
      title: "Dep Crypto - Forfeitable",
      type: "boolean",
    },
    {
      name: "dep_crypto_non_sticky",
      title: "Dep Crypto - Non sticky bonus",
      type: "boolean",
    },
    {
      headingTitle: "Crypto bonus (Exclusive)",
      headingIcon: <CurrencyBitcoinIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep_crypto_percentage_exclusive",
      title: "Dep Crypto - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "dep_crypto_max_bonus_exclusive",
      title: "Dep Crypto - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep_crypto_wager_requirements_exclusive",
      title: "Dep Crypto - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep_crypto_expiration_time_exclusive",
      title: "Dep Crypto - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep_crypto_max_bet_exclusive",
      title: "Dep Crypto - Max bet",
    },
    {
      name: "dep_crypto_max_withdrawal_exclusive",
      title: "Dep Crypto - Max withdrawal",
    },
    {
      name: "dep_crypto_min_deposit_exclusive",
      title: "Dep Crypto - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep_crypto_bonus_code_exclusive",
      title: "Dep Crypto - Bonus code",
    },
    {
      name: "dep_crypto_wager_requirements_deposit_exclusive",
      title: "Dep Crypto - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "dep_crypto_wager_requirements_bonus_exclusive",
      title: "Dep Crypto - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "dep_crypto_forfeitable_exclusive",
      title: "Dep Crypto - Forfeitable",
      type: "boolean",
    },
    {
      name: "dep_crypto_non_sticky_exclusive",
      title: "Dep Crypto - Non sticky bonus",
      type: "boolean",
    },
    { headingTitle: "Customer support", headingIcon: <ContactSupportIcon /> },
    {
      name: "contact_form",
      title: "Contact form",
      type: "boolean",
    },
    {
      name: "phone_support",
      title: "Phone support",
      type: "boolean",
    },
    {
      name: "live_chat",
      title: "Live chat",
      type: "boolean",
    },
    {
      name: "live_chat_hours",
      title: "Live chat hours",
    },
    { headingTitle: "Casino in markets", headingIcon: <StoreIcon /> },
    {
      name: "casino_in_markets",
      type: "plaintext",
      marketPath: [
        {
          shortName: "AU",
          name: "Australia",
          url: "/root/casinos/au",
        },
        { shortName: "CA", name: "Canada", url: "/root/casinos/ca" },
        { shortName: "IN", name: "India", url: "/root/casinos/in" },
        {
          shortName: "NZ",
          name: "New Zeeland",
          url: "/root/casinos/nz",
        },
        {
          shortName: "ZA",
          name: "South Africa",
          url: "/root/casinos/za",
        },
        {
          shortName: "UK",
          name: "United Kingdom",
          url: "/root/casinos/uk",
        },
        {
          shortName: "ROW",
          name: "Rest of the World",
          url: "/root/casinos/row",
        },
      ],
      gridMd: 12,
      customFilter: (data, internal_name) => {
        return data.filter((d) => d.internal_name === internal_name)
      },
    },
  ],
  casinosCountry: [
    {
      headingTitle: "General information",
      headingIcon: <InfoIcon />,
    },
    {
      name: "name",
      title: "Casino",
      component: "th",
      scope: "row",
      displayInBrowse: true,
      mainTableColumn: true,
      disabled: true,
    },
    {
      name: "tracking_url_country",
      title: "Tracking url (country)",
      displayInBrowse: true,
      hideInMarket: "row",
    },
    {
      name: "tracking_url_country_exclusive",
      title: "Exclusive tracking url (country)",
      displayInBrowse: true,
      hideInMarket: "row",
    },
    {
      name: "tc_url_country",
      title: "T&C url (country)",
    },
    {
      name: "tc_text",
      title: "T&C text",
    },
    {
      name: "website_url_country",
      title: "Website url (country)",
    },
    {
      name: "operator_country",
      title: "Operator (country)",
      type: "autocomplete",
      optionsApi: "/root/operators/root",
    },
    {
      name: "established_country",
      title: "Established (country)",
      type: "date",
      datePickerViews: ["year", "month"],
      inputLabelProps: {
        shrink: true,
      },
    },
    {
      name: "status_country",
      title: "Status",
      type: "select",
      options: Options.statuses,
    },
    {
      name: "payment_providers",
      title: "Payment providers",
      type: "autocomplete",
      optionsApi: "/root/paymentproviders/root",
      multiple: true,
      json: true,
      gridMd: 6,
    },
    {
      headingTitle: "Manual score",
      headingIcon: <GradeIcon />,
      collapsedByDefault: true,
    },
    {
      name: "manual_score",
      title: "Manual score",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
        max: 10,
      },
      errorText: "Must be a value between 0 and 10",
      displayInBrowse: true,
    },
    {
      name: "manual_score_start",
      title: "Start date",
      type: "date",
      datePickerViews: ["year", "month", "day"],
      inputLabelProps: {
        shrink: true,
      },
    },
    {
      name: "manual_score_end",
      title: "End date",
      type: "date",
      datePickerViews: ["year", "month", "day"],
      inputLabelProps: {
        shrink: true,
      },
    },
    { headingTitle: "Manual positions", headingIcon: <FormatListNumberedIcon /> },
    {
      name: "manual_positions",
      title: "Manual positions",
      type: "list",
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    { headingTitle: "Limits", headingIcon: <AttachMoneyIcon /> },
    {
      name: "max_withdrawal_limit",
      title: "Max withdrawal limit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "max_withdrawal_limit_timespan",
      title: "Max withdrawal limit - Timespan",
      type: "select",
      options: Options.timespan,
    },
    {
      name: "min_withdrawal_limit",
      title: "Min withdrawal limit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "min_deposit_limit",
      title: "Min deposit limit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    { headingTitle: "First deposit bonus", headingIcon: <CardGiftcardIcon /> },
    {
      name: "dep1_percentage",
      title: "Dep 1 - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "dep1_max_bonus",
      title: "Dep 1 - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_wager_requirements",
      title: "Dep 1 - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_expiration_time",
      title: "Dep 1 - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_max_bet",
      title: "Dep 1 - Max bet",
    },
    {
      name: "dep1_max_withdrawal",
      title: "Dep 1 - Max withdrawal",
    },
    {
      name: "dep1_min_deposit",
      title: "Dep 1 - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_bonus_code",
      title: "Dep 1 - Bonus code",
    },
    {
      name: "dep1_wager_requirements_deposit",
      title: "Dep 1 - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "dep1_wager_requirements_bonus",
      title: "Dep 1 - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "dep1_forfeitable",
      title: "Dep 1 - Forfeitable",
      type: "boolean",
    },
    {
      name: "dep1_non_sticky",
      title: "Dep 1 - Non sticky bonus",
      type: "boolean",
    },
    {
      headingTitle: "First deposit bonus (Exclusive)",
      headingIcon: <CardGiftcardIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep1_percentage_exclusive",
      title: "Dep 1 - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "dep1_max_bonus_exclusive",
      title: "Dep 1 - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_wager_requirements_exclusive",
      title: "Dep 1 - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_expiration_time_exclusive",
      title: "Dep 1 - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_max_bet_exclusive",
      title: "Dep 1 - Max bet",
    },
    {
      name: "dep1_max_withdrawal_exclusive",
      title: "Dep 1 - Max withdrawal",
    },
    {
      name: "dep1_min_deposit_exclusive",
      title: "Dep 1 - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_bonus_code_exclusive",
      title: "Dep 1 - Bonus code",
    },
    {
      name: "dep1_wager_requirements_deposit_exclusive",
      title: "Dep 1 - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "dep1_wager_requirements_bonus_exclusive",
      title: "Dep 1 - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "dep1_forfeitable_exclusive",
      title: "Dep 1 - Forfeitable",
      type: "boolean",
    },
    {
      name: "dep1_non_sticky_exclusive",
      title: "Dep 1 - Non sticky bonus",
      type: "boolean",
    },
    { headingTitle: "First deposit spins", headingIcon: <SyncIcon /> },
    {
      name: "dep1_spins_amount",
      title: "Dep 1 Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_spins_wager_requirements",
      title: "Dep 1 Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_spins_max_bet",
      title: "Dep 1 Spins - Max bet",
    },
    {
      name: "dep1_spins_min_deposit",
      title: "Dep 1 Spins - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_spins_expiration_time",
      title: "Dep 1 Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_spins_value",
      title: "Dep 1 Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.01",
        min: 0,
      },
    },
    {
      name: "dep1_spins_max_withdrawal",
      title: "Dep 1 Spins - Max withdrawal",
    },
    {
      name: "dep1_spins_bonus_code",
      title: "Dep 1 Spins - Bonus code",
    },
    {
      name: "dep1_spins_credited",
      title: "Dep 1 Spins - Credited",
    },
    {
      headingTitle: "First deposit spins (Exclusive)",
      headingIcon: <SyncIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep1_spins_amount_exclusive",
      title: "Dep 1 Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_spins_wager_requirements_exclusive",
      title: "Dep 1 Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_spins_max_bet_exclusive",
      title: "Dep 1 Spins - Max bet",
    },
    {
      name: "dep1_spins_min_deposit_exclusive",
      title: "Dep 1 Spins - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_spins_expiration_time_exclusive",
      title: "Dep 1 Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_spins_value_exclusive",
      title: "Dep 1 Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.01",
        min: 0,
      },
    },
    {
      name: "dep1_spins_max_withdrawal_exclusive",
      title: "Dep 1 Spins - Max withdrawal",
    },
    {
      name: "dep1_spins_bonus_code_exclusive",
      title: "Dep 1 Spins - Bonus code",
    },
    {
      name: "dep1_spins_credited_exclusive",
      title: "Dep 1 Spins - Credited",
    },
    { headingTitle: "First deposit other", headingIcon: <StarsIcon /> },
    {
      name: "dep1_other_type",
      title: "Dep 1 Other - Type",
    },
    {
      name: "dep1_other_max_bonus",
      title: "Dep 1 Other - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_other_expiration_time",
      title: "Dep 1 Other - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_other_max_bet",
      title: "Dep 1 Other - Max bet",
    },
    {
      name: "dep1_other_min_deposit",
      title: "Dep 1 Other - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_other_wager_requirements",
      title: "Dep 1 Other - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_other_credited",
      title: "Dep 1 Other - Credited",
    },
    {
      headingTitle: "First deposit other (Exclusive)",
      headingIcon: <StarsIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep1_other_type_exclusive",
      title: "Dep 1 Other - Type",
    },
    {
      name: "dep1_other_max_bonus_exclusive",
      title: "Dep 1 Other - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_other_expiration_time_exclusive",
      title: "Dep 1 Other - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_other_max_bet_exclusive",
      title: "Dep 1 Other - Max bet",
    },
    {
      name: "dep1_other_min_deposit_exclusive",
      title: "Dep 1 Other - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep1_other_wager_requirements_exclusive",
      title: "Dep 1 Other - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep1_other_credited_exclusive",
      title: "Dep 1 Other - Credited",
    },
    {
      headingTitle: "Second deposit bonus",
      headingIcon: <CardGiftcardIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep2_percentage",
      title: "Dep 2 - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "dep2_max_bonus",
      title: "Dep 2 - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep2_wager_requirements",
      title: "Dep 2 - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep2_expiration_time",
      title: "Dep 2 - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep2_max_bet",
      title: "Dep 2 - Max bet",
    },
    {
      name: "dep2_max_withdrawal",
      title: "Dep 2 - Max withdrawal",
    },
    {
      name: "dep2_min_deposit",
      title: "Dep 2 - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep2_bonus_code",
      title: "Dep 2 - Bonus code",
    },
    {
      name: "dep2_wager_requirements_deposit",
      title: "Dep 2 - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "dep2_wager_requirements_bonus",
      title: "Dep 2 - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "dep2_forfeitable",
      title: "Dep 2 - Forfeitable",
      type: "boolean",
    },
    {
      name: "dep2_non_sticky",
      title: "Dep 2 - Non sticky bonus",
      type: "boolean",
    },
    {
      headingTitle: "Second deposit spins",
      headingIcon: <SyncIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep2_spins_amount",
      title: "Dep 2 Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep2_spins_wager_requirements",
      title: "Dep 2 Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep2_spins_max_bet",
      title: "Dep 2 Spins - Max bet",
    },
    {
      name: "dep2_spins_min_deposit",
      title: "Dep 2 Spins - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep2_spins_expiration_time",
      title: "Dep 2 Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep2_spins_value",
      title: "Dep 2 Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.01",
        min: 0,
      },
    },
    {
      name: "dep2_spins_max_withdrawal",
      title: "Dep 2 Spins - Max withdrawal",
    },
    {
      name: "dep2_spins_bonus_code",
      title: "Dep 2 Spins - Bonus code",
    },
    {
      name: "dep2_spins_credited",
      title: "Dep 2 Spins - Credited",
    },
    {
      headingTitle: "Third deposit bonus",
      headingIcon: <CardGiftcardIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep3_percentage",
      title: "Dep 3 - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "dep3_max_bonus",
      title: "Dep 3 - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep3_wager_requirements",
      title: "Dep 3 - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep3_expiration_time",
      title: "Dep 3 - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep3_max_bet",
      title: "Dep 3 - Max bet",
    },
    {
      name: "dep3_max_withdrawal",
      title: "Dep 3 - Max withdrawal",
    },
    {
      name: "dep3_min_deposit",
      title: "Dep 3 - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep3_bonus_code",
      title: "Dep 3 - Bonus code",
    },
    {
      name: "dep3_wager_requirements_deposit",
      title: "Dep 3 - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "dep3_wager_requirements_bonus",
      title: "Dep 3 - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "dep3_forfeitable",
      title: "Dep 3 - Forfeitable",
      type: "boolean",
    },
    {
      name: "dep3_non_sticky",
      title: "Dep 3 - Non sticky bonus",
      type: "boolean",
    },
    {
      headingTitle: "Third deposit spins",
      headingIcon: <SyncIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep3_spins_amount",
      title: "Dep 3 Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep3_spins_wager_requirements",
      title: "Dep 3 Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep3_spins_max_bet",
      title: "Dep 3 Spins - Max bet",
    },
    {
      name: "dep3_spins_min_deposit",
      title: "Dep 3 Spins - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep3_spins_expiration_time",
      title: "Dep 3 Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep3_spins_value",
      title: "Dep 3 Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.01",
        min: 0,
      },
    },
    {
      name: "dep3_spins_max_withdrawal",
      title: "Dep 3 Spins - Max withdrawal",
    },
    {
      name: "dep3_spins_bonus_code",
      title: "Dep 3 Spins - Bonus code",
    },
    {
      name: "dep3_spins_credited",
      title: "Dep 3 Spins - Credited",
    },
    {
      headingTitle: "Fourth deposit bonus",
      headingIcon: <CardGiftcardIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep4_percentage",
      title: "Dep 4 - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "dep4_max_bonus",
      title: "Dep 4 - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep4_wager_requirements",
      title: "Dep 4 - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep4_expiration_time",
      title: "Dep 4 - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep4_max_bet",
      title: "Dep 4 - Max bet",
    },
    {
      name: "dep4_max_withdrawal",
      title: "Dep 4 - Max withdrawal",
    },
    {
      name: "dep4_min_deposit",
      title: "Dep 4 - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep4_bonus_code",
      title: "Dep 4 - Bonus code",
    },
    {
      name: "dep4_wager_requirements_deposit",
      title: "Dep 4 - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "dep4_wager_requirements_bonus",
      title: "Dep 4 - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "dep4_forfeitable",
      title: "Dep 4 - Forfeitable",
      type: "boolean",
    },
    {
      name: "dep4_non_sticky",
      title: "Dep 4 - Non sticky bonus",
      type: "boolean",
    },
    {
      headingTitle: "Fourth deposit spins",
      headingIcon: <SyncIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep4_spins_amount",
      title: "Dep 4 Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep4_spins_wager_requirements",
      title: "Dep 4 Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep4_spins_max_bet",
      title: "Dep 4 Spins - Max bet",
    },
    {
      name: "dep4_spins_min_deposit",
      title: "Dep 4 Spins - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep4_spins_expiration_time",
      title: "Dep 4 Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep4_spins_value",
      title: "Dep 4 Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.01",
        min: 0,
      },
    },
    {
      name: "dep4_spins_max_withdrawal",
      title: "Dep 4 Spins - Max withdrawal",
    },
    {
      name: "dep4_spins_bonus_code",
      title: "Dep 4 Spins - Bonus code",
    },
    {
      name: "dep4_spins_credited",
      title: "Dep 4 Spins - Credited",
    },
    {
      headingTitle: "Fifth deposit bonus",
      headingIcon: <CardGiftcardIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep5_percentage",
      title: "Dep 5 - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "dep5_max_bonus",
      title: "Dep 5 - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep5_wager_requirements",
      title: "Dep 5 - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep5_expiration_time",
      title: "Dep 5 - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep5_max_bet",
      title: "Dep 5 - Max bet",
    },
    {
      name: "dep5_max_withdrawal",
      title: "Dep 5 - Max withdrawal",
    },
    {
      name: "dep5_min_deposit",
      title: "Dep 5 - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep5_bonus_code",
      title: "Dep 5 - Bonus code",
    },
    {
      name: "dep5_wager_requirements_deposit",
      title: "Dep 5 - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "dep5_wager_requirements_bonus",
      title: "Dep 5 - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "dep5_forfeitable",
      title: "Dep 5 - Forfeitable",
      type: "boolean",
    },
    {
      name: "dep5_non_sticky",
      title: "Dep 5 - Non sticky bonus",
      type: "boolean",
    },
    {
      headingTitle: "Fifth deposit spins",
      headingIcon: <SyncIcon />,
      collapsedByDefault: true,
    },
    {
      name: "dep5_spins_amount",
      title: "Dep 5 Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep5_spins_wager_requirements",
      title: "Dep 5 Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep5_spins_max_bet",
      title: "Dep 5 Spins - Max bet",
    },
    {
      name: "dep5_spins_min_deposit",
      title: "Dep 5 Spins - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "dep5_spins_expiration_time",
      title: "Dep 5 Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "dep5_spins_value",
      title: "Dep 5 Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.01",
        min: 0,
      },
    },
    {
      name: "dep5_spins_max_withdrawal",
      title: "Dep 5 Spins - Max withdrawal",
    },
    {
      name: "dep5_spins_bonus_code",
      title: "Dep 5 Spins - Bonus code",
    },
    {
      name: "dep5_spins_credited",
      title: "Dep 5 Spins - Credited",
    },
    { headingTitle: "No deposit cash", headingIcon: <MoneyIcon /> },
    {
      name: "nodep_cash_amount",
      title: "No dep Cash - Amount",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "nodep_cash_wager_requirements",
      title: "No dep Cash - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_cash_max_withdrawal",
      title: "No dep Cash - Max withdrawal",
    },
    {
      name: "nodep_cash_expiration_time",
      title: "No dep Cash - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_cash_max_bet",
      title: "No dep Cash - Max bet",
    },
    {
      name: "nodep_cash_bonus_code",
      title: "No dep Cash - Bonus code",
    },
    {
      name: "nodep_cash_credited",
      title: "No dep Cash - Credited",
    },
    {
      headingTitle: "No deposit cash (Exclusive)",
      headingIcon: <MoneyIcon />,
      collapsedByDefault: true,
    },
    {
      name: "nodep_cash_amount_exclusive",
      title: "No dep Cash - Amount",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "nodep_cash_wager_requirements_exclusive",
      title: "No dep Cash - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_cash_max_withdrawal_exclusive",
      title: "No dep Cash - Max withdrawal",
    },
    {
      name: "nodep_cash_expiration_time_exclusive",
      title: "No dep Cash - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_cash_max_bet_exclusive",
      title: "No dep Cash - Max bet",
    },
    {
      name: "nodep_cash_bonus_code_exclusive",
      title: "No dep Cash - Bonus code",
    },
    {
      name: "nodep_cash_credited_exclusive",
      title: "No dep Cash - Credited",
    },
    { headingTitle: "No deposit spins", headingIcon: <SyncIcon /> },
    {
      name: "nodep_spins_amount",
      title: "No dep Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_spins_wager_requirements",
      title: "No dep Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_spins_max_withdrawal",
      title: "No dep Spins - Max withdrawal",
    },
    {
      name: "nodep_spins_spin_value",
      title: "No dep Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "nodep_spins_expiration_time",
      title: "No dep Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_spins_max_bet",
      title: "No dep Spins - Max bet",
    },
    {
      name: "nodep_spins_bonus_code",
      title: "No dep Spins - Bonus code",
    },
    {
      name: "nodep_spins_credited",
      title: "No dep Spins - Credited",
    },
    {
      name: "nodep_other_type",
      title: "No dep Other - Type of bonus",
    },
    {
      headingTitle: "No deposit spins (Exclusive)",
      headingIcon: <SyncIcon />,
      collapsedByDefault: true,
    },
    {
      name: "nodep_spins_amount_exclusive",
      title: "No dep Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_spins_wager_requirements_exclusive",
      title: "No dep Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_spins_max_withdrawal_exclusive",
      title: "No dep Spins - Max withdrawal",
    },
    {
      name: "nodep_spins_spin_value_exclusive",
      title: "No dep Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "nodep_spins_expiration_time_exclusive",
      title: "No dep Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "nodep_spins_max_bet_exclusive",
      title: "No dep Spins - Max bet",
    },
    {
      name: "nodep_spins_bonus_code_exclusive",
      title: "No dep Spins - Bonus code",
    },
    {
      name: "nodep_spins_credited_exclusive",
      title: "No dep Spins - Credited",
    },
    {
      name: "nodep_other_type_exclusive",
      title: "No dep Other - Type of bonus",
    },
    { headingTitle: "Live bonus", headingIcon: <PersonIcon /> },
    {
      name: "live_bonus_percentage",
      title: "Live Bonus - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
    },
    {
      name: "live_bonus_max_bonus",
      title: "Live Bonus - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "live_bonus_deposit_for_max_bonus",
      title: "Live Bonus - Deposit for max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "live_bonus_wager_requirements",
      title: "Live Bonus - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "live_bonus_expiration_time",
      title: "Live Bonus - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "live_bonus_max_bet",
      title: "Live Bonus - Max bet",
    },
    {
      name: "live_bonus_max_withdrawal",
      title: "Live Bonus - Max withdrawal",
    },
    {
      name: "live_bonus_min_deposit",
      title: "Live Bonus - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "live_bonus_bonus_code",
      title: "Live Bonus - Bonus code",
    },
    {
      name: "live_bonus_forfeitable",
      title: "Live Bonus - Forfeitable",
      type: "boolean",
    },
    {
      name: "live_bonus_non_sticky",
      title: "Live Bonus - Non sticky bonus",
      type: "boolean",
    },
    {
      headingTitle: "Live bonus (Exclusive)",
      headingIcon: <PersonIcon />,
      collapsedByDefault: true,
    },
    {
      name: "live_bonus_percentage_exclusive",
      title: "Live Bonus - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
    },
    {
      name: "live_bonus_max_bonus_exclusive",
      title: "Live Bonus - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "live_bonus_deposit_for_max_bonus_exclusive",
      title: "Live Bonus - Deposit for max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "live_bonus_wager_requirements_exclusive",
      title: "Live Bonus - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "live_bonus_expiration_time_exclusive",
      title: "Live Bonus - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "live_bonus_max_bet_exclusive",
      title: "Live Bonus - Max bet",
    },
    {
      name: "live_bonus_max_withdrawal_exclusive",
      title: "Live Bonus - Max withdrawal",
    },
    {
      name: "live_bonus_min_deposit_exclusive",
      title: "Live Bonus - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "live_bonus_bonus_code_exclusive",
      title: "Live Bonus - Bonus code",
    },
    {
      name: "live_bonus_forfeitable_exclusive",
      title: "Live Bonus - Forfeitable",
      type: "boolean",
    },
    {
      name: "live_bonus_non_sticky_exclusive",
      title: "Live Bonus - Non sticky bonus",
      type: "boolean",
    },
    { headingTitle: "Cashback bonus", headingIcon: <MoneyIcon /> },
    {
      name: "cashback_bonus_percentage",
      title: "Cashback Bonus - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "cashback_bonus_min_deposit",
      title: "Cashback Bonus - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "cashback_bonus_credited",
      title: "Cashback Bonus - Credited",
    },
    {
      name: "cashback_bonus_wager_requirements",
      title: "Cashback Bonus - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "cashback_bonus_bonus_code",
      title: "Cashback Bonus - Bonus code",
    },
    {
      name: "cashback_bonus_expiration_time",
      title: "Cashback Bonus - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "cashback_bonus_max_withdrawal",
      title: "Cashback bonus - Max withdrawal",
      type: "number",
    },
    {
      name: "cashback_bonus_min_win",
      title: "Cashback Bonus - Min Win",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "cashback_bonus_max_win",
      title: "Cashback Bonus - Max Win",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "cashback_bonus_vip_level",
      title: "Cashback Bonus - VIP Level",
    },
    {
      headingTitle: "Cashback bonus (Exclusive)",
      headingIcon: <MoneyIcon />,
      collapsedByDefault: true,
    },
    {
      name: "cashback_bonus_percentage_exclusive",
      title: "Cashback Bonus - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "cashback_bonus_min_deposit_exclusive",
      title: "Cashback Bonus - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "cashback_bonus_credited_exclusive",
      title: "Cashback Bonus - Credited",
    },
    {
      name: "cashback_bonus_wager_requirements_exclusive",
      title: "Cashback Bonus - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "cashback_bonus_bonus_code_exclusive",
      title: "Cashback Bonus - Bonus code",
    },
    {
      name: "cashback_bonus_expiration_time_exclusive",
      title: "Cashback Bonus - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "cashback_bonus_max_withdrawal_exclusive",
      title: "Cashback bonus - Max withdrawal",
      type: "number",
    },
    {
      name: "cashback_bonus_min_win_exclusive",
      title: "Cashback Bonus - Min Win",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "cashback_bonus_max_win_exclusive",
      title: "Cashback Bonus - Max Win",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "cashback_bonus_vip_level_exclusive",
      title: "Cashback Bonus - VIP Level",
    },
    { headingTitle: "Betting bonus", headingIcon: <PaidIcon /> },
    {
      name: "betting_percentage",
      title: "Betting - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "betting_max_bonus",
      title: "Betting - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "betting_wager_requirements",
      title: "Betting - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "betting_expiration_time",
      title: "Betting - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "betting_max_bet",
      title: "Betting - Max bet",
    },
    {
      name: "betting_max_withdrawal",
      title: "Betting - Max withdrawal",
    },
    {
      name: "betting_min_deposit",
      title: "Betting - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "betting_bonus_code",
      title: "Betting - Bonus code",
    },
    {
      name: "betting_wager_requirements_deposit",
      title: "Betting - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "betting_wager_requirements_bonus",
      title: "Betting - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "betting_forfeitable",
      title: "Betting - Forfeitable",
      type: "boolean",
    },
    {
      name: "betting_non_sticky",
      title: "Betting - Non sticky bonus",
      type: "boolean",
    },

    {
      headingTitle: "Betting bonus (Exclusive)",
      headingIcon: <PaidIcon />,
      collapsedByDefault: true,
    },
    {
      name: "betting_percentage_exclusive",
      title: "Betting - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "betting_max_bonus_exclusive",
      title: "Betting - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "betting_wager_requirements_exclusive",
      title: "Betting - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "betting_expiration_time_exclusive",
      title: "Betting - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "betting_max_bet_exclusive",
      title: "Betting - Max bet",
    },
    {
      name: "betting_max_withdrawal_exclusive",
      title: "Betting - Max withdrawal",
    },
    {
      name: "betting_min_deposit_exclusive",
      title: "Betting - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "betting_bonus_code_exclusive",
      title: "Betting - Bonus code",
    },
    {
      name: "betting_wager_requirements_deposit_exclusive",
      title: "Betting - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "betting_wager_requirements_bonus_exclusive",
      title: "Betting - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "betting_forfeitable_exclusive",
      title: "Betting - Forfeitable",
      type: "boolean",
    },
    {
      name: "betting_non_sticky_exclusive",
      title: "Betting - Non sticky bonus",
      type: "boolean",
    },

    {
      headingTitle: "High Roller Welcome bonus",
      headingIcon: <CasinoIcon />,
    },
    {
      name: "high_roller_percentage",
      title: "High Roller - Percentage",
      type: "number",
      inputProps: {
        min: 0,
        max: 1000,
      },
      errorText: "Must be a value between 0 and 1000",
    },
    {
      name: "high_roller_max_bonus",
      title: "High Roller - Max bonus",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "high_roller_wager_requirements",
      title: "High Roller - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "high_roller_expiration_time",
      title: "High Roller - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "high_roller_max_bet",
      title: "High Roller - Max bet",
    },
    {
      name: "high_roller_max_withdrawal",
      title: "High Roller - Max withdrawal",
    },
    {
      name: "high_roller_min_deposit",
      title: "High Roller - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "high_roller_bonus_code",
      title: "High Roller - Bonus code",
    },
    {
      name: "high_roller_wager_requirements_deposit",
      title: "High Roller - Wager reqs deposit",
      type: "boolean",
    },
    {
      name: "high_roller_wager_requirements_bonus",
      title: "High Roller - Wager reqs bonus",
      type: "boolean",
    },
    {
      name: "high_roller_forfeitable",
      title: "High Roller - Forfeitable",
      type: "boolean",
    },
    {
      name: "high_roller_non_sticky",
      title: "High Roller - Non sticky bonus",
      type: "boolean",
    },
    {
      headingTitle: "High Roller Welcome spins",
      headingIcon: <RollerSkatingIcon />,
    },
    {
      name: "high_roller_spins_amount",
      title: "High Roller Spins - Amount",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "high_roller_spins_wager_requirements",
      title: "High Roller Spins - Wager requirements",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "high_roller_spins_max_bet",
      title: "High Roller Spins - Max bet",
    },
    {
      name: "high_roller_spins_min_deposit",
      title: "High Roller Spins - Min deposit",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
      },
    },
    {
      name: "high_roller_spins_expiration_time",
      title: "High Roller Spins - Expiration time (days)",
      type: "number",
      inputProps: {
        min: 0,
      },
    },
    {
      name: "high_roller_spins_value",
      title: "High Roller Spins - Spin value",
      type: "number",
      inputProps: {
        step: "0.01",
        min: 0,
      },
    },
    {
      name: "high_roller_spins_max_withdrawal",
      title: "High Roller Spins - Max withdrawal",
    },
    {
      name: "high_roller_spins_bonus_code",
      title: "High Roller Spins - Bonus code",
    },
    {
      name: "high_roller_spins_credited",
      title: "High Roller Spins - Credited",
    },
  ],
  casinosSite: [
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "name",
      title: "Casino",
      mainTableColumn: true,
      disabled: true,
      displayInBrowse: true,
    },
    {
      name: "tracking_url_site",
      title: "Tracking url (site)",
      displayInBrowse: true,
    },
    {
      name: "status_site",
      title: "Status",
      type: "select",
      options: Options.statuses,
    },
    {
      name: "has_review",
      title: "Has review on site",
      type: "boolean",
    },
    {
      name: "Final_Score",
      title: "Final Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepSpins_Score",
      title: "DepSpins Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Studios_Rank_Score",
      title: "Studios Rank Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Studios_Count_Score",
      title: "Studios Count Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Studios_TopStudios_Score",
      title: "Studios TopStudios Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Total_GameStudio_Score",
      title: "Total GameStudio Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Total_Payments_Score",
      title: "Total Payments Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepBonus_Score",
      title: "DepBonus Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Cashback_Score",
      title: "Cashback Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepBonus_Percentage_Score",
      title: "DepBonus Percentage Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepBonus_Amount_Score",
      title: "DepBonus Amount Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepBonus_Wagering_Score",
      title: "DepBonus Wagering Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepBonus_MaxBet_Score",
      title: "DepBonus MaxBet Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepBonus_MaxWin_Score",
      title: "DepBonus MaxWin Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepSpins_Value_Score",
      title: "DepSpins Value Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepSpins_MaxWin_Score",
      title: "DepSpins MaxWin Score",
      type: "number",
      disabled: true,
    },
    {
      name: "DepSpins_Wagering_Score",
      title: "DepSpins Wagering Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Total_Limits_Score",
      title: "Total Limits Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Total_WelcomeBonus_Score",
      title: "Total WelcomeBonus Score",
      type: "number",
      disabled: true,
    },
    {
      name: "before_eq_Final_Score",
      title: "before eq Final Score",
      type: "number",
      disabled: true,
    },
    {
      name: "NoDep_Bonus_Score",
      title: "NoDep Bonus Score",
      type: "number",
      disabled: true,
    },
    {
      name: "FSNoDep_Bonus_Score",
      title: "FSNoDep Bonus Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Total_NoDep_Score",
      title: "Total NoDep Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Total_Service_Score",
      title: "Total Service Score",
      type: "number",
      disabled: true,
    },
    {
      name: "Total_License_Score",
      title: "Total License Score",
      type: "number",
      disabled: true,
    },
  ],
  casinoPlatformsRoot: [
    {
      name: "internal_name",
      title: "Internal name",
      hidden: true,
      internalNameField: true,
    },
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "name",
      title: "Name",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
    },
    {
      name: "established",
      title: "Established",
      type: "date",
      datePickerViews: ["year", "month"],
      inputLabelProps: {
        shrink: true,
      },
    },
    { name: "website_url", title: "Website url", displayInBrowse: true },
    {
      name: "email_address",
      title: "Email address",
    },
    {
      name: "game_studios",
      title: "Game studios",
      type: "autocomplete",
      multiple: true,
      json: true,
      optionsApi: "/root/gamestudios/root",
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    {
      name: "payment_providers",
      title: "Payment providers",
      type: "autocomplete",
      multiple: true,
      json: true,
      optionsApi: "/root/paymentproviders/root",
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    {
      name: "licenses",
      title: "Licenses",
      type: "autocomplete",
      multiple: true,
      json: true,
      optionsApi: "/root/licenses/root",
      gridXs: 12,
      gridSm: 12,
      gridMd: 12,
    },
    {
      name: "has_review",
      title: "Has review on site",
      type: "boolean",
    },
  ],
  affiliateProgramsRoot: [
    {
      name: "internal_name",
      title: "Internal name",
      hidden: true,
      internalNameField: true,
    },
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "name",
      title: "Name",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
    },
    {
      name: "status",
      title: "Status",
      type: "select",
      options: Options.affiliateProgramStatuses,
    },
    { name: "website_url", title: "Website url" },
    { name: "social_media_url", title: "Social media url" },
    {
      name: "affiliate_platform",
      title: "Affiliate platform",
      type: "autocomplete",
      optionsApi: "/root/affiliateplatforms/root",
      gridMd: 6,
    },
    {
      name: "has_review",
      title: "Has review on site",
      type: "boolean",
    },
    { headingTitle: "Deal information", headingIcon: <MonetizationOnIcon /> },
    { name: "revenue_share_deal", title: "Deal - Revenue share", type: "boolean" },
    { name: "cpa_deal", title: "Deal - CPA", type: "boolean" },
    {
      name: "hybrid_deal",
      title: "Deal - Hybrid",
      type: "boolean",
    },
    { name: "negative_carry_over", title: "Negative carry over", type: "boolean" },
    {
      name: "admin_fee",
      title: "Admin fee",
      type: "number",
      inputProps: {
        min: 0,
        max: 100,
      },
      InputProps: {
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      },
      gridXs: 2,
      gridSm: 2,
      gridMd: 2,
    },
    { headingTitle: "Connected Casinos", headingIcon: <FormatListBulletedIcon /> },
    {
      name: "connected_casino",
      apiPath: "/root/casinos/root/",
      itemField: "internal_name",
      fieldToCompare: "affiliate_program",
      fieldToShow: "name",
      type: "plaintext",
      gridMd: 12,
    },
    { headingTitle: "Invoice information", headingIcon: <ReceiptIcon /> },
    {
      name: "company_name",
      title: "Company name",
    },
    {
      name: "company_number",
      title: "Company number",
    },
    { name: "vat_number", title: "VAT number" },
    { name: "address", title: "Address" },
    {
      name: "payment_reference",
      title: "Payout reference name",
    },
  ],
  affiliatePlatformsRoot: [
    {
      name: "internal_name",
      title: "Internal name",
      hidden: true,
      internalNameField: true,
    },
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "name",
      title: "Name",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
    },
    {
      name: "established",
      title: "Established",
      type: "date",
      datePickerViews: ["year", "month"],
      inputLabelProps: {
        shrink: true,
      },
    },
    { name: "website_url", title: "Website url" },
    {
      name: "has_review",
      title: "Has review on site",
      type: "boolean",
    },
  ],
  casinoLicensesRoot: [
    { name: "id", title: "Id", hidden: true, internalNameField: true },
    { headingTitle: "Casino license information", headingIcon: <InfoIcon /> },
    {
      name: "casino",
      title: "Casino",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
      type: "autocomplete",
      optionsApi: "/root/casinos/root",
      gridMd: 6,
    },
    {
      name: "license",
      title: "License",
      type: "autocomplete",
      optionsApi: "/root/licenses/root",
      displayInBrowse: true,
      gridMd: 6,
    },
    { name: "license_number", title: "License number" },
    { name: "sub_license_number", title: "Sub-license number" },
    { name: "verification_url", title: "Verification url" },
  ],
  licensesRoot: [
    {
      name: "internal_name",
      title: "Internal name",
      hidden: true,
      internalNameField: true,
    },
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "name",
      title: "Name",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
    },
    { name: "website_url", title: "Website url" },
    {
      name: "country",
      title: "Licensing country",
      type: "autocomplete",
      multiple: false,
      json: false,
      options: Options.countries,
    },
    {
      name: "has_review",
      title: "Has review on site",
      type: "boolean",
    },
    {
      name: "type",
      title: "License type",
      type: "autocomplete",
      multiple: false,
      json: false,
      options: Options.licenseTypes,
    },
  ],
  operatorsRoot: [
    {
      name: "internal_name",
      title: "Internal name",
      hidden: true,
      internalNameField: true,
    },
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "name",
      title: "Name",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
    },
    { name: "address", title: "Address" },
    { name: "website_url", title: "Website url" },
    {
      name: "established",
      title: "Established",
      type: "date",
      datePickerViews: ["year", "month"],
      inputLabelProps: {
        shrink: true,
      },
    },
    { name: "social_media_url", title: "Social media url" },
  ],
  paymentProvidersRoot: [
    {
      name: "internal_name",
      title: "Internal name",
      hidden: true,
      internalNameField: true,
    },
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "name",
      title: "Name",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
    },
    { name: "website_url", title: "Website url" },
    {
      name: "established",
      title: "Established",
      type: "date",
      datePickerViews: ["year", "month"],
      inputLabelProps: {
        shrink: true,
      },
    },
    { name: "email_address", title: "Email address" },
    { name: "phone_number", title: "Phone number" },
    { headingTitle: "Banking information", headingIcon: <AccountBalanceIcon /> },
    {
      name: "type",
      title: "Type",
      type: "select",
      options: Options.paymentProviderType,
      displayInBrowse: true,
    },
    { name: "instant_deposit", title: "Instant deposit", type: "boolean" },
    { name: "withdrawal", title: "Withdrawal", type: "boolean" },
    { name: "pay_by_invoice", title: "Pay by invoice", type: "boolean" },
    { headingTitle: "Other" },
    { name: "score", title: "Score", displayInBrowse: true },
    {
      name: "score_crypto",
      title: "Crypto score",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
        max: 10,
      },
      errorText: "Must be a value between 0 and 10, one decimal allowed",
      displayInBrowse: true,
    },
    {
      name: "has_review",
      title: "Has review on site",
      type: "boolean",
    },
    { name: "withdrawal_speed", title: "Withdrawal speed" },
  ],
  gameStudiosRoot: [
    {
      name: "internal_name",
      title: "Internal name",
      hidden: true,
      internalNameField: true,
    },
    { headingTitle: "General information", headingIcon: <InfoIcon /> },
    {
      name: "name",
      title: "Name",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
    },
    { name: "company_name", title: "Company name" },
    { name: "address", title: "Address" },
    { name: "website_url", title: "Website url" },
    { name: "social_media_url", title: "Social media url" },
    { headingTitle: "Games", headingIcon: <GamesIcon /> },
    {
      name: "third_party_tested_by",
      title: "3:rd party tested by",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.thirdPartyTestedBy,
      gridMd: 6,
      skipGridColumns: 6,
    },
    { name: "has_desktop_games", title: "Desktop games", type: "boolean" },
    { name: "has_mobile_games", title: "Mobile games", type: "boolean" },
    { name: "has_jackpot_games", title: "Jackpot games", type: "boolean" },
    { name: "has_live_games", title: "Live games", type: "boolean" },
    { name: "has_table_games", title: "Table games", type: "boolean" },
    { headingTitle: "Other" },
    {
      name: "score",
      title: "Score",
      type: "number",
      inputProps: {
        step: "0.1",
        min: 0,
        max: 1,
      },
      errorText: "Must be a value between 0 and 1",
      displayInBrowse: true,
      gridXs: 6,
      gridSm: 6,
      gridMd: 6,
    },
    {
      name: "has_review",
      title: "Has review on site",
      type: "boolean",
      gridXs: 6,
      gridSm: 6,
      gridMd: 6,
    },
    {
      name: "blacklist_mga",
      title: "MGA blacklist",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.countries,
      selectAll: true,
      gridXs: 6,
      gridSm: 6,
      gridMd: 6,
    },
    {
      name: "blacklist_curacao",
      title: "Curacao blacklist",
      type: "autocomplete",
      multiple: true,
      json: true,
      options: Options.countries,
      selectAll: true,
      gridXs: 6,
      gridSm: 6,
      gridMd: 6,
    },
  ],
  gameStudioLicensesRoot: [
    { name: "id", title: "Id", hidden: true, internalNameField: true },
    { headingTitle: "Game supplier license information", headingIcon: <InfoIcon /> },
    {
      name: "game_studio",
      title: "Game supplier",
      component: "th",
      scope: "row",
      required: true,
      displayInBrowse: true,
      mainTableColumn: true,
      nameField: true,
      type: "autocomplete",
      optionsApi: "/root/gamestudios/root",
      gridMd: 6,
    },
    {
      name: "license",
      title: "License",
      type: "autocomplete",
      optionsApi: "/root/licenses/root",
      displayInBrowse: true,
      gridMd: 6,
    },
    { name: "license_number", title: "License number" },
    { name: "sub_license_number", title: "Sub-license number" },
    { name: "verification_url", title: "Verification url" },
  ],
}
