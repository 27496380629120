import React from "react"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

export default function AlertDialog(props) {
  const {
    open,
    title,
    content,
    cancelLabel,
    continueLabel,
    cancelEvent,
    continueEvent,
  } = props

  return (
    <Dialog
      open={open}
      onClose={cancelEvent}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="div">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelEvent} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={continueEvent} color="primary" autoFocus>
          {continueLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.object,
  cancelLabel: PropTypes.string,
  continueLabel: PropTypes.string,
  cancelEvent: PropTypes.func,
  continueEvent: PropTypes.func,
}
