import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Skeleton from "@mui/material/Skeleton"

const useStyles = makeStyles((theme) => ({
  buttonCell: {
    width: 135,
  },
  buttonSkeleton: {
    display: "inline-block",
    margin: "0 12px",
  },
  headerTextSkeleton: {
    width: 120,
  },
  first: {
    width: 200,
  },
  second: {
    width: 400,
  },
  textSkeleton: {
    margin: "10px 0",
  },
}))

const TableSkeleton = (props) => {
  const classes = useStyles()

  const { rows } = props

  const tableBody = []
  for (let i = 0; i < rows; i++) {
    tableBody.push(
      <TableRow key={`skeleton-table-${i}`}>
        <TableCell className={classes.buttonCell}>
          <Skeleton
            className={classes.buttonSkeleton}
            variant="circle"
            width={20}
            height={20}
          />
          <Skeleton
            className={classes.buttonSkeleton}
            variant="circle"
            width={20}
            height={20}
          />
        </TableCell>

        <TableCell>
          <Skeleton
            className={`${classes.textSkeleton} ${classes.first}`}
            variant="text"
          />
        </TableCell>
        <TableCell>
          <Skeleton
            className={`${classes.textSkeleton} ${classes.second}`}
            variant="text"
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.buttonCell}></TableCell>
              <TableCell>
                <Skeleton className={classes.headerTextSkeleton} variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton className={classes.headerTextSkeleton} variant="text" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

TableSkeleton.propTypes = {
  rows: PropTypes.number.isRequired,
}

export default TableSkeleton
