import React from "react"
import { makeStyles, createStyles } from "@mui/styles"
import List from "@mui/material/List"
import AppMenuItem from "./AppMenuItem"
import { Menu as appMenuItems } from "../../config"
import Divider from "@mui/material/Divider"

const AppMenu = () => {
  const classes = useStyles()

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {appMenuItems.map((item, index) =>
        item.divider ? (
          <Divider className={classes.divider} key={"menu-divider-" + index} />
        ) : (
          <AppMenuItem {...item} key={index} />
        )
      )}
    </List>
  )
}

const drawerWidth = 240

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: "#fff",
    },
    divider: {
      margin: theme.spacing(1),
      borderColor: "#484848",
    },
  })
)

export default AppMenu
